export class Menu {
    constructor(public id: number,
                public titleEn: string,
                public titleAr: string,
                public routerLink: string,
                public href: string,
                public icon: string,
                public SameSite: boolean,
                public target: string,
                public hasSubMenu: boolean,
                public parentId: number) { }
} 