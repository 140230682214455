import { APP_CONFIG } from 'src/app/app.config';
import { Menu } from './menu.model';
export const verticalMenuItems = [ 
    new Menu (1, 'Dashboard','الصفحة الرئيسية', '/', null, 'dashboard',true , null, false, 0),

    new Menu (2,'Accounting','مالية', null, null, 'payments',true , null, true, 0),
    new Menu (3, 'Finance Statement','القيود المالية', '/financeStatement', null, 'request_quote',true , null, false, 2), 
    new Menu (4,'Receipt Voucher','سند القبض', '/receiptVoucher', null, 'receipt_long',true , null, false, 2), 
    new Menu (5,'Pay Tenants Dues','سداد استحقاقات المستأجرين','/payTenantsDues', null, 'payments',true , null, false, 2), 
    new Menu (6,'Tenants Dues','الاستحقاقات', '/tenantsDues', null, 'receipt',true , null, false, 2), 
    new Menu (7, 'Account Statement','كشف حساب', '/accountStatement', null, 'summarize',true , null, false, 2), 
    new Menu (8,'Trail Balance','ميزان المراجعة','/trailBalance', null, 'balance',true , null, false, 2),
    new Menu (9, 'financial Accounts Tree','عرض شجرة الحسابات', '/financialAccountsTree', null, 'account_tree',true , null, false, 2), 
    new Menu (10, 'Banck Accounts','الحسابات البنكية', '/bankAccounts', null, 'account_balance',true , null, false, 2), 

    new Menu (11,'Reports','تقارير', null, null, 'summarize',true , null, true, 0),
    new Menu (12,'Follow Up On Collections','متابعة التحصيل','/followUpOnCollections', null, 'follow_the_signs',true , null, false, 11),
    new Menu (13,'Rental Statistics','احصاءات الايجارات','/rentalStatistics', null, 'signal_cellular_alt',true , null, false, 11),
    new Menu (14,'Summary','ملخص','/summary', null, 'summarize',true , null, false, 11),
    new Menu (15,'Pms Fees Report','رسوم ادارة الاملاك','/pmsFeesReport', null, 'insights',true , null, false, 11),
    new Menu (15,'Security Deposit Report','مبالغ التامين','/securityDepositReport', null, 'insights',true , null, false, 11),
    new Menu (15,'Annual Rent Report','الاجرة المفترضة لعام','/annualRentReport', null, 'insights',true , null, false, 11),
    new Menu (15,'Units Report','الوحدات العقارية','/reports/unitsReport', null, 'apartment',true , null, false, 11),
    new Menu (15,'Avaliable Units','الوحدات المتاحة','/reports/avaliableUnits', null, 'apartment',true , null, false, 11),
    new Menu (15,'Dues Entitlements','الاستخقاقات المستحقة','/reports/duesEntitlements', null, 'payments',true , null, false, 11),


    new Menu (15,'Rent Agreements','عقود الايجار', '/rentAgreements', null, 'receipt_long',true , null, false, 0), 
    new Menu (16,'Tenants','المستأجرين', '/tenants', null, 'vpn_key',true , null, false, 0), 
    new Menu (17,'Legal Representative','الممثلين القانونيين', '/AllLegalRepresentatives', null, 'vpn_key',true , null, false, 0), 
    
    // new Menu (9,'Contact With Tenants','التواصل مع المستأجرين', '/contactWithTenants', null, 'connect_without_contact',true , null, false, 0),
    new Menu (18,'Show Notification','عرض الإشعارات', '/dueNotifications', null, 'notifications',true , null, false, 0), 

    new Menu (19,'Handing Units','تسليم واستلام الوحدة', '/handingUnit', null, 'assignment_return',true , null, false, 0), 

    new Menu (20, 'Owners','الملاك' ,'/owners', null, 'people_alt',true , null, false, 0),

    new Menu (21,'properties','الاملاك', null, null, 'apartment',true , null, true, 0),
    new Menu (22,'Compounds','المجمعات' ,'/compounds', null, 'maps_home_work',true , null, false, 21),
    new Menu (23,'Buildings','المباني' ,'/buildings', null, 'house',true , null, false, 21),
    new Menu (24,'Real Estate Units','الوحدات العقارية' ,'/realEstateUnits', null, 'apartment',true , null, false, 21),
    
    new Menu (25,'Service','الخدمات', null, null, 'home_repair_service',true , null, true, 0),
    new Menu (26,'Meter Service','عدادات الخدمة' ,'/meterService', null, 'radar',true , null, false, 25),
    new Menu (27,'Accessories & Fitting','التجهيزات والملحقات' ,'/accessoriesAndFittings', null, 'home_repair_service',true , null, false, 25),

    new Menu (28, 'Users','مستخدمي إدارة الأملاك', '/users', null, 'supervisor_account',true , null, false, 0), 

    new Menu (29, 'settings','الاعدادات العامة لمدیر الأملاك', '/pmsSettings', null, 'settings',true , null, false, 0), 
    
    
]

export const horizontalMenuItems = [ 
    new Menu (1, 'Dashboard','الصفحة الرئيسية', '/', null, 'dashboard',true , null, false, 0),
    new Menu (2, 'Finance Statement','القيود المالية', '/financeStatement', null, 'request_quote',true , null, false, 0), 
    new Menu (3,'Receipt Voucher','سند القبض', '/receiptVoucher', null, 'receipt_long',true , null, false, 0), 
    new Menu (4,'Pay Tenants Dues','سداد استحقاقات المستأجرين','/payTenantsDues', null, 'payments',true , null, false, 0), 
    new Menu (5,'Tenants Dues','الاستحقاقات', '/tenantsDues', null, 'receipt',true , null, false, 0), 
    new Menu (6,'Rent Agreements','عقود الايجار', '/rentAgreements', null, 'receipt_long',true , null, false, 0), 
    new Menu (7,'Legal Representative','الممثلين القانونيين', '/AllLegalRepresentatives', null, 'vpn_key',true , null, false, 0), 
    new Menu (8,'Tenants','المستأجرين', '/tenants', null, 'vpn_key',true , null, false, 0), 
    // new Menu (9,'Contact With Tenants','التواصل مع المستأجرين', '/contactWithTenants', null, 'connect_without_contact',true , null, false, 0),
    new Menu (10,'Handing Units','تسليم واستلام الوحدة', '/handingUnit', null, 'assignment_return',true , null, false, 0), 
    new Menu (11,'Show Notification','عرض الإشعارات', '/dueNotifications', null, 'notifications',true , null, false, 0), 
    new Menu (12, 'financial Accounts Tree','عرض شجرة الحسابات', '/financialAccountsTree', null, 'account_tree',true , null, false, 0), 
    new Menu (13, 'Banck Accounts','الحسابات البنكية', '/bankAccounts', null, 'account_balance',true , null, false, 0), 
    new Menu (14, 'Owners','الملاك' ,'/owners', null, 'people_alt',true , null, false, 0),
    new Menu (15,'properties','الاملاك', null, null, 'apartment',true , null, true, 0),
    new Menu (16,'Compounds','المجمعات' ,'/compounds', null, 'maps_home_work',true , null, false, 15),
    new Menu (17,'Buildings','المباني' ,'/buildings', null, 'house',true , null, false, 15),
    new Menu (18,'Real Estate Units','الوحدات العقارية' ,'/realEstateUnits', null, 'apartment',true , null, false, 15),
    new Menu (19,'Service','الخدمات', null, null, 'home_repair_service',true , null, true, 0),
    new Menu (20,'Meter Service','عدادات الخدمة' ,'/meterService', null, 'radar',true , null, false, 19),
    new Menu (21,'Accessories & Fitting','التجهيزات والملحقات' ,'/accessoriesAndFittings', null, 'home_repair_service',true , null, false, 19),
    new Menu (22, 'Users','مستخدمي إدارة الأملاك', '/users', null, 'supervisor_account',true , null, false, 0), 
    new Menu (23, 'settings','الاعدادات العامة لمدیر الأملاك', '/pmsSettings', null, 'settings',true , null, false, 0), 
    new Menu (24,'Reports','تقارير', null, null, 'summarize',true , null, true, 0),
    new Menu (25,'Follow Up On Collections','متابعة التحصيل','/followUpOnCollections', null, 'follow_the_signs',true , null, false, 24),
    new Menu (26,'Rental Statistics','احصاءات الايجارات','/rentalStatistics', null, 'signal_cellular_alt',true , null, false, 24),
    new Menu (27,'Summary','ملخص','/summary', null, 'summarize',true , null, false, 24),
    new Menu (28,'Accounting','مالية', null, null, 'payments',true , null, true, 0),
    new Menu (29,'Trail Balance','ميزان المراجعة','/trailBalance', null, 'balance',true , null, false, 28),
    new Menu (30, 'Account Statement','كشف حساب', '/accountStatement', null, 'summarize',true , null, false, 28), 
    new Menu (31, 'Finance Statement','القيود المالية', '/financeStatement', null, 'request_quote',true , null, false, 28), 
]