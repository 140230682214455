import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {APP_CONFIG} from '../app.config';
import { User } from '../interfaces/propertyManagement/users';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private currentUser: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(private http: HttpClient) {
    this.loadUser();
   }
  ON_REST_API: string = APP_CONFIG.NO_API_ENDPOINT;
  REST_API: string = APP_CONFIG.API_ENDPOINT;
  logIn(data: any[]): Observable<any> {
    let API_URL = `${this.ON_REST_API}/propertyManagementUserLogin`;
    // let API_URL = `${this.ON_REST_API}/propertyManagementUserLoginLocalHost`;
    return this.http.post(API_URL,data,{withCredentials:true})
      .pipe(
        catchError(this.handleError)
      )
  }

  sendVerfication(data: any): Observable<any> {
    let API_URL = `${this.ON_REST_API}/verifyPropertyManagementUserLogin`;
    // let API_URL = `${this.ON_REST_API}/verifyPropertyManagementUserLoginLocalHost`;
    return this.http.post(API_URL,data,{withCredentials:true})
      .pipe(
        catchError(this.handleError)
      )
  }

  verifiedLogIn(data: any): Observable<any> {
    let API_URL = `${this.ON_REST_API}/propertyManagementUserLoginToOneAccount`;
    return this.http.post(API_URL,data,{withCredentials:true})
      .pipe(
        catchError(this.handleError)
      )
  }

  loginByUserName(data: any): Observable<any> {
    let API_URL = `${this.ON_REST_API}/propertyManagementUserByUserName`;
    return this.http.post(API_URL,data,{withCredentials:true})
      .pipe(
        catchError(this.handleError)
      )
  }

  cancelTheVerfication(data: any): Observable<any> {
    let API_URL = `${this.ON_REST_API}/cancelPropertyManagementUserLogin`;
    return this.http.post(API_URL,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  checkSession(): Observable<any> {
    let API_URL = `${this.REST_API}/propertyManagementAccounts/getSessionData`;
    return this.http.get(API_URL)
      .pipe(
        catchError(this.handleError)
      )
  }

  loadUser(){
    var user = localStorage.getItem('user');
      if(user){
        this.currentUser.next(JSON.parse(user));
      }else{
        this.currentUser.next(false);
      }
  }

  setUser(user){
    this.currentUser.next(user);
  }

  getUser(){
    return this.currentUser.asObservable();
  }

  getCurrentUser(){
    return JSON.parse(localStorage.getItem('user'));
  }

  updateUserInfo(userInfo){
    var user = JSON.parse(localStorage.getItem('user'));
    user.firstName = userInfo.firstName;
    user.grandfatherName = userInfo.grandfatherName;
    user.fatherName = userInfo.fatherName;
    user.lastName = userInfo.lastName;
    user.mobileNumber = userInfo.mobileNumber;
    user.userName = userInfo.userName;
    user.address = userInfo.address;
    user.gender = userInfo.gender;
    localStorage.setItem('user',JSON.stringify(user));
    window.location.reload();
  }
  
  updateUserPic(personalPhotoLink){
    var user = JSON.parse(localStorage.getItem('user'));
    user.personalPhotoLink = personalPhotoLink;
    localStorage.setItem('user',JSON.stringify(user));
    window.location.reload();
  }

  deleteCurrentUserLogo(){
    var user = JSON.parse(localStorage.getItem('user'));
    user.pmaLogoLink = '';
    localStorage.setItem('user',JSON.stringify(user));
    this.setUser(user);
  }

  addCurrentUserLogo(link){
    var user = JSON.parse(localStorage.getItem('user'));
    user.pmaLogoLink = link;
    localStorage.setItem('user',JSON.stringify(user));
    this.setUser(user);
  }

  updateUserPremission(user: User){
    let API_URL = `${this.REST_API}/propertyManagementAccountUserPermissions/findPermissionByPropertyManagementAccountUserPermission`;
    var data = {user_id: user.id}
    this.http.get(API_URL+this.jsonToQueryString(data)).pipe(
      catchError(this.handleError)
      ).subscribe( (res: any)=>{
        console.log(res);
        if(res.session == 'session destroyed'){
          this.logOut().subscribe(res=>{
            if(res && res.msg == 'done'){
              this.destoryStorage();
            }
          });
        }
        if(res.isAdmin == 0){
          user.permission = res.powers;
          localStorage.setItem('user',JSON.stringify(user));
          this.setUser(user);
        }
      });
  }

  logOut(): Observable<any>{
    let API_URL = `${this.ON_REST_API}/destroyedSession`;
    return this.http.get(API_URL)
    .pipe(
      catchError(this.handleError)
      )
  }

  destoryStorage(): Observable<any>{
    this.currentUser.next(null);
    localStorage.removeItem('user');
    localStorage.removeItem('sessionID');
    localStorage.removeItem('token');
    return this.currentUser;
  }
  // Error 
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.message.includes('login')){
      localStorage.removeItem('user');
      localStorage.removeItem('sessionID');
      localStorage.removeItem('token');
      window.location.reload();
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  jsonToQueryString(json) {
    return '?' + 
        Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
  }
}
