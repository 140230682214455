import { Injectable } from '@angular/core';
import { Settings } from 'src/app/app.settings.model';
import { AppSettings } from '../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class ThemeSettingsService {

  constructor(public appSettings:AppSettings) { }

  setThemeSettings(settings){
    var setting = JSON.stringify(settings);
    localStorage.setItem('settings', setting);
  }

  getThemeSettings(){
   var settings  = localStorage.getItem('settings');
   var setting = JSON.parse(settings)
   if(settings){
    for (var key in setting){
      this.appSettings.settings[key] = setting[key];
    }
   }
  }
}
