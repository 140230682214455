import { Component, OnInit,Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OwnersService } from './../../../../services/PMAServices/owners/owners.service';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AppConfirmService } from '../../../../shared/app-confirm/app-confirm.service';
import { APP_CONFIG } from '../../../../app.config';
import { TenantsService } from 'src/app/services/PMAServices/tenants/tenants.service';

@Component({
  selector: 'app-tenant-attachmenet',
  templateUrl: './tenant-attachmenet.component.html',
  styleUrls: ['./tenant-attachmenet.component.scss']
})
export class TenantAttachmenetComponent implements OnInit {

  currentIndex: any = -1;
  showFlag: any = false;
  imageObject:any=[];
  files: any=[];
  REST_No_API: string = APP_CONFIG.NO_API_ENDPOINT;
  NO_API_URL = `${this.REST_No_API}`;
  showAddForm: boolean = false;
  form:UntypedFormGroup;
  srcResult:any;
  isPdf: boolean = false;
  
  constructor(private tenantsService: TenantsService,
    public dialogRef: MatDialogRef<TenantAttachmenetComponent>,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,public fb: UntypedFormBuilder,
    private translate: TranslateService,
    private ownersService: OwnersService,
    @Inject(MAT_DIALOG_DATA) public imagesData: any) { }
  ngOnInit(): void {
    this.form = this.fb.group({
      id:this.imagesData.id,
      file_text: [null],
      file: [null]
    });
    this.showImages();
  }

  showImages(){
    if(this.imagesData.type == 'tenant'){
      var data = {
        tenant_id: this.imagesData.id
      }
      this.tenantsService.getAttachements(data).subscribe(res=>{
        this.imageObject = [];
        if(res && res.length != 0){
          res.forEach(element => {
            this.imageObject.push({
              image: this.NO_API_URL+element.docPath,
              thumbImage: this.NO_API_URL+element.docPath,
              imageData: element,
              title: element.description
            })
          });
        }
      });
    }

    if(this.imagesData.type == 'tenantLegalRepresentative'){
      var Legaldata = {
        id: this.imagesData.id,
        feildName: "tenantLegalRepresentativeId",
        tableName: "legalRepresentativeDocs"
      }
      this.ownersService.getDocumentInfoByFiledName(Legaldata).subscribe(res=>{
        this.imageObject = [];
        if(res && res.length != 0){
          res.forEach(element => {
            this.imageObject.push({
              image: this.NO_API_URL+element.docPath,
              thumbImage: this.NO_API_URL+element.docPath,
              imageData: element,
              title: element.description
            })
          });
        }
      });
    }
  }

  AddFile(){
    this.showAddForm = true;
  }

  backToPrevious(){
    this.dialogRef.close(null);
  }

  removeImage(image){
    this.confirmService
    .confirm({ message: this.translate.instant('CONFIRM') })
    .subscribe((res) => {
      if (res) {
        this.loader.open();
        if(this.imagesData.type == 'tenant'){
            var data = {
              docId:image.id,
              tenantId: image.tenantId,
              path: image.docPath
            }
          
            this.tenantsService.deleteTenantAttachement(data).subscribe(res=>{
              this.loader.close();
              if(res.msg == 'done'){
                this.showImages();
                this.snack.open(this.translate.instant('SUCCESS_DELETE_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });
              }
            },err=>{
              this.loader.close();
              this.snack.open(this.translate.instant('ERROR_MESSAGE'), this.translate.instant('ERROR'), { duration: 4000 });
            })

          } 
        if(this.imagesData.type == 'tenantLegalRepresentative'){
            var LegalImagedata = {
              doc_id:image.id,
              lr_id:image.legalRepresentativeId,
              tenantLegalRepresentativeId:image.tenantLegalRepresentativeId,
              path: image.docPath
            }
            this.ownersService.deleteLegalAttachement(LegalImagedata).subscribe(res=>{
              this.loader.close();
              if(res.msg == 'done'){
                this.showImages();
                this.snack.open(this.translate.instant('SUCCESS_DELETE_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });
              }
            },err=>{
              this.loader.close();
              this.snack.open(this.translate.instant('ERROR_MESSAGE'), this.translate.instant('ERROR'), { duration: 4000 });
            })

          } 
        if(this.imagesData.type == 'tenantTaxNumber'){
            var TaxImagedata = {
              docId:image.id,
              owner_id:this.imagesData.ownerId,
              path: image.docPath
            }
            // this.tenantsService.deleteTaxAttachement(TaxImagedata).subscribe(res=>{
            //   this.loader.close();
            //   if(res.msg == 'done'){
            //     this.showImages();
            //     this.snack.open(this.translate.instant('SUCCESS_DELETE_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });
            //   }
            // },err=>{
            //   this.loader.close();
            //   this.snack.open(this.translate.instant('ERROR_MESSAGE'), this.translate.instant('ERROR'), { duration: 4000 });
            // })

          } 
        }
      });
  }

  showLightbox(index) {
    this.currentIndex = index;
    this.showFlag = true;
  }
  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }

  uploadPhoto(event){
    console.log(event.value)  
  }

  onFileSelected(e) {
    var files= [];
    var importedFiles = (e.target as HTMLInputElement).files;

    for(var key in importedFiles){

      files.push(importedFiles[key]);

      const reader = new FileReader();
      reader.onload = () => {
        this.srcResult=[];
        this.srcResult.push({fileUrl:reader.result as string});
        var ifPdf = (reader.result as string).split(',')
        if(ifPdf[0].includes('pdf')){
          this.isPdf = true;
        }else{
          this.isPdf = false;
        }
      }

      if(importedFiles[key].size)
        reader.readAsDataURL(files[key])
    }

    this.form.patchValue({
      file: files
    });
    
    this.form.get('file').updateValueAndValidity();
  }

  removeTempImage(img){
    this.srcResult.forEach((element,index) => {
      if(element.fileUrl == img.fileUrl){
        this.srcResult.splice(index,1)
        this.form.patchValue({
          identityPhoto: null
        });
      }
    });
  }

  submit(){
    if(this.imagesData.type == 'tenant'){
      if(this.form.valid && this.form.value.file && this.form.value.file[0]?.name){
        this.loader.open(); 
        var data = new FormData();
        data.append('owner_id',this.form.value.id);
        data.append('text',this.form.value.file_text);
        data.append('file',this.form.value.file[0],this.form.value.file[0].name);
        this.tenantsService.addTenantDocs(data).subscribe(res=>{
          if(res.msg == 'done'){
            this.showImages();
            this.srcResult=[];
            this.snack.open(this.translate.instant('SUCCESS_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });
            this.form.reset();
          }
          this.loader.close(); 
        },err=>{
          this.loader.close(); 
          this.snack.open(this.translate.instant('ERROR_MESSAGE'), this.translate.instant('ERROR'), { duration: 4000 });
        })
      }else{
        this.snack.open(this.translate.instant('PLEASE_UPLOAD_MESSAGE'), this.translate.instant('ERROR'), { duration: 4000 });
      }
    }

    if(this.imagesData.type == 'tenantLegalRepresentative'){
      if(this.form.valid && this.form.value.file && this.form.value.file[0]?.name){
        var legaldata = new FormData();
        legaldata.append('tenantId',this.imagesData.tenantLegalRepresentatives[0].tenantId);
        legaldata.append('tenantLegalRepresentativeId',this.imagesData.id);
        legaldata.append('lr_id',this.imagesData.id);
        legaldata.append('text',this.form.value.file_text);
        legaldata.append('file',this.form.value.file[0],this.form.value.file[0].name);
        this.loader.open(); 
        this.ownersService.addLegalDocument(legaldata).subscribe(res=>{
          if(res.msg == 'done'){
            this.showImages();
            this.srcResult=[];
            this.snack.open(this.translate.instant('SUCCESS_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });
            this.form.reset();
          }
          this.loader.close();
        },err=>{
          this.loader.close(); 
          this.snack.open(this.translate.instant('ERROR_MESSAGE'), this.translate.instant('ERROR'), { duration: 4000 });
        });
      }else{
        this.snack.open(this.translate.instant('PLEASE_UPLOAD_MESSAGE'), this.translate.instant('ERROR'), { duration: 4000 });
      }
    }
  }

}
