import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { APP_CONFIG } from './../../../app.config';
import { emailValidator } from '../../utils/app-validators';
import { LoginService } from './../../../services/login.service';
import { UsersService } from '../../../services/PMAServices/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-top-info-content',
  templateUrl: './top-info-content.component.html',
  styleUrls: ['./top-info-content.component.scss']
})
export class TopInfoContentComponent implements OnInit {
  @Input('showInfoContent') showInfoContent:boolean = false;
  @Output() onCloseInfoContent: EventEmitter<any> = new EventEmitter();
  contactForm: UntypedFormGroup;
  controls = [
    { name: 'Notifications', checked: true },
    { name: 'Tasks', checked: true },
    { name: 'Events', checked: false },
    { name: 'Downloads', checked: true },
    { name: 'Messages', checked: true },
    { name: 'Updates', checked: false },
    { name: 'Settings', checked: true }
  ]

  REST_No_API: string = APP_CONFIG.NO_API_ENDPOINT;
  NO_API_URL = `${this.REST_No_API}`;
  currentUser = this.loginService.getCurrentUser();

  constructor(public formBuilder: UntypedFormBuilder, private loginService: LoginService, private usersService: UsersService,private snackBar: MatSnackBar,
    private translate: TranslateService,) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, emailValidator])],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  public onContactFormSubmit(values:Object):void {
    if (this.contactForm.valid) {
      this.usersService.sendEmailMessage(values).subscribe(res=>{
        if(res){
          this.snackBar.open(this.translate.instant('SUCCESS_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });
          this.contactForm.reset();
          this.closeInfoContent(false);
        }
      });
    }
  }

  public closeInfoContent(event){
    this.onCloseInfoContent.emit(event);
  }

}
