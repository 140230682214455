import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { param } from 'jquery';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from '../../../app.config';


@Injectable({
  providedIn: 'root'
})
export class DuesService {

  REST_API: string = APP_CONFIG.API_ENDPOINT;
  API_URL = `${this.REST_API}`;
  REST_No_API: string = APP_CONFIG.NO_API_ENDPOINT;
  NO_API_URL = `${this.REST_No_API}`;

  constructor(private http: HttpClient) { }

  getAllDuesList(data): Observable<any> {
    return this.http.post(this.API_URL+'/dues/findAllDuesDataTable', data)
      .pipe(
        catchError(this.handleError)
      )
  }

  addDue(data): Observable<any> {
    return this.http.post(this.API_URL+'/dues/createDue', data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateDue(data): Observable<any> {
    return this.http.post(this.API_URL+'/dues/updateDue', data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteDue(data): Observable<any> {
    return this.http.post(this.API_URL+'/dues/deleteDue', data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getCollectionsDuesRentAgreement(data): Observable<any> {
    return this.http.get(this.API_URL+'/rentAgreements/findActiveRentAgreementsByTenantNameOrUnitNameOrRentAgreementId'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getTenantRentAgreementAutoCom(data): Observable<any> {
    return this.http.get(this.NO_API_URL+'/rentAgreements/findActiveRentAgreementsByTenantNameOrUnitNameOrRentAgreementId'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getCollectionsDuesList(data): Observable<any> {
    return this.http.get(this.API_URL+'/dues/findDuesForSpecificAgreement'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllUnpaifDues(data): Observable<any> {
    return this.http.get(this.API_URL+'/dues/findAllUnpaidDues'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllUnpaifDuesByTenant(data): Observable<any> {
    return this.http.get(this.API_URL+'/dues/findAllUnpaidDuesByTenants'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  ownerFilterFollowUpDues(data): Observable<any> {
    return this.http.get(this.API_URL+'/dues/findAllUnpaidDuesByOwner'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  filterFollowUpDues(data): Observable<any> {
    return this.http.get(this.API_URL+'/dues/findAllUnpaidDuesByBuildingCompound'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getTenantBalance(data): Observable<any> {
    return this.http.get(this.API_URL+'/tenants/getTenantBalance'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  updateTrackingDate(data): Observable<any> {
    return this.http.post(this.API_URL+'/dues/changeDueTrackingDate',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  sendTrackingDueMessage(data): Observable<any> {
    return this.http.post(this.API_URL+'/dues/sendTrackingDueMessage',data)
      .pipe(
        catchError(this.handleError)
      )
  }
  
  makeTrackingDueCall(data): Observable<any> {
    return this.http.post(this.API_URL+'/dues/makeTrackingDueCall',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  payTenantDue(data): Observable<any> {
    return this.http.post(this.API_URL+'/duePayments/createDuePayment',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getTenantTotalDuesAmountById(data): Observable<any> {
    return this.http.get(this.API_URL+'/dues/findTotalDueRestAmountByTenantId'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getUnpaiedDuesForSpecificAgreement(data): Observable<any> {
    return this.http.get(this.API_URL+'/dues/findUnpaiedDuesForSpecificAgreement'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getRetnalStatistics(data): Observable<any> {
    return this.http.get(this.API_URL+'/dues/findAmountOfDuesByParameterId'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getCurrency(data): Observable<any>{
    return this.http.get(this.NO_API_URL+'/getCurrency/'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

  getTaxNumber(data): Observable<any>{
    return this.http.get(this.API_URL+'/rentAgreements/findTaxNumberForUnitOrPMAByAgreementId'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

  saveFollowingNote(data): Observable<any> {
    return this.http.post(this.API_URL+'/dues/updateDueFollowingNote',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  findAllTenantsWithBalanceAndDues(): Observable<any> {
    return this.http.get(this.API_URL+'/dues/findAllTenantsWithBalanceAndDues')
      .pipe(
        catchError(this.handleError)
      )
  }

  

  getPmaFeesReport(data): Observable<any> {
    return this.http.get(this.API_URL+'/dues/findAmountOfPMAFeesByParameterId'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getSecurityDeposits(data): Observable<any> {
    return this.http.get(this.API_URL+'/dues/findAmountOfSecurityDepositByParameterId'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getAnnualRentReport(data): Observable<any> {
    return this.http.get(this.API_URL+'/rentAgreements/findAnnualRentWithoutTaxOfLastAgreementByParameterId'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.message.includes('login')){
      localStorage.removeItem('user');
      localStorage.removeItem('sessionID');
      localStorage.removeItem('token');
      window.location.reload();
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  jsonToQueryString(json) {
    return '?' + 
        Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
  }
}
