import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { ThemeSettingsService } from './theme-settings/theme-settings.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  
  public settings:Settings;

  constructor( private translate: TranslateService,public appSettings:AppSettings,private themeSettingsService:ThemeSettingsService) {
    this.settings = this.appSettings.settings; 
  }
  selected = '';
  setInitialAppLanguage(){
    let language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    var lan = localStorage.getItem('Language');
    if(lan){
      this.setLanguage(lan);
      if(lan == 'ar'){
        this.settings.rtl = true;
        this.themeSettingsService.setThemeSettings(this.settings)
      }
      this.selected = lan;
    }
  }

  getLanguage(){
    return localStorage.getItem('Language');
  }

  setLanguage(lan){
    this.translate.use(lan);
    this.selected = lan;
    return localStorage.setItem('Language',lan);
  }
}
