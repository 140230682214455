import { Component, ViewChild} from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { LanguageService } from './services/language.service';
import { ThemeSettingsService } from './services/theme-settings/theme-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public settings: Settings;
  constructor(public appSettings:AppSettings,private languageService: LanguageService,private themeSettingsService: ThemeSettingsService ){
      this.settings = this.appSettings.settings;
  } 

  ngOnInit() {
    this.languageService.setInitialAppLanguage();
    this.themeSettingsService.getThemeSettings();
   }
}