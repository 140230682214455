import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/services/login.service';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class HorizontalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  public menuItems:Array<any>;
  public settings: Settings;
  public currentLag:string;
  currentUser = this.loginService.getUser();
  constructor(public appSettings:AppSettings,private loginService: LoginService, public menuService:MenuService,private translate: TranslateService) { 
    this.settings = this.appSettings.settings;
    this.currentLag = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res=>{
      this.currentLag = res.lang;
    })
  }

  ngOnInit() {
    this.currentUser.subscribe(res=>{
      if(res.isAdmin == 0){
        this.menuItems.forEach(element => {
          element.allow = false; 
          if(element.titleEn == 'Dashboard'){
            if(res.permission?.dashboardReport == true){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Users'){
            if(res.permission?.propertyManagementUsers_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'settings'){
            if(res.permission?.propertyManagerSettings_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'financial Accounts Tree'){
            if(res.permission?.showFinancialAccountsTree_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Account Statement'){
            if(res.permission?.showFinancialAccountsTree_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Finance Statement'){
            if(res.permission?.financialRestrictions_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Banck Accounts'){
            if(res.permission?.bankAccounts_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Owners'){
            if(res.permission?.owners_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'properties'){
            if(res.permission?.compounds_page.includes('SHOW') ||
              res.permission?.buildings_page.includes('SHOW') ||
              res.permission?.realEstateUnits_page.includes('SHOW')
              ){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Compounds'){
            if(res.permission?.compounds_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Buildings'){
            if(res.permission?.buildings_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Real Estate Units'){
            if(res.permission?.realEstateUnits_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Service'){
            if(res.permission?.metersService_page.includes('SHOW') ||
              res.permission?.accessoriesAndFittings_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Meter Service'){
            if(res.permission?.metersService_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Accessories & Fitting'){
            if(res.permission?.accessoriesAndFittings_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Tenants'){
            if(res.permission?.propertyManagerSettings_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Rent Agreements'){
            if(res.permission?.tenants_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Tenants Dues'){
            if(res.permission?.tenantsDues_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Receipt Voucher'){
            if(res.permission?.receiptVoucher_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Pay Tenants Dues'){
            if(res.permission?.payTenantsDues_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          if(element.titleEn == 'Handing Units'){
            element.allow = true; 
          }
          if(element.titleEn == 'Show Notification'){
            element.allow = true; 
          }
          if(element.titleEn == 'Follow Up On Collections'){
            if(res.permission?.trackingDues_page && res.permission?.trackingDues_page.includes('SHOW')){
              element.allow = true;
            }
          }
          if(element.titleEn == 'Trail Balance'){            
            if(res.permission?.trialBalance_page && res.permission?.trialBalance_page.includes('SHOW')){
              element.allow = true;
            }
          }

          if(element.titleEn == 'Accounting'){
            if(res.permission?.showFinancialAccountsTree_page.includes('SHOW') ||
              res.permission?.financialRestrictions_page.includes('SHOW') ||
              res.permission?.trialBalance_page.includes('SHOW')
              ){
              element.allow = true; 
            }
          }

          if(element.titleEn == 'Reports'){
            if(res.permission?.trackingDues_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          
          if(element.titleEn == 'Rental Statistics'){
            element.allow = true;
          }
          
          if(element.titleEn == 'Contact With Tenants'){
            if(res.permission?.summary_page && res.permission?.summary_page.includes('SHOW')){
              element.allow = true; 
            }
          }

          if(element.titleEn == 'Due Notification List'){
            element.allow = true; 
          }

          if(element.titleEn == 'Legal Representative'){
            element.allow = true; 
          }

          if(element.titleEn == 'Summary'){
            if(res.permission?.summary_page.includes('SHOW')){
              element.allow = true; 
            }
          }
          
          if(element.titleEn == 'Pms Fees Report'){
            element.allow = true; 
            // if(res.permission?.pmsFeesReport_page && res.permission?.pmsFeesReport_page.includes('SHOW')){
            // }
          }

          if(element.titleEn == 'Security Deposit Report'){
            element.allow = true; 
            // if(res.permission?.pmsFeesReport_page && res.permission?.pmsFeesReport_page.includes('SHOW')){
            // }
          }

          if(element.titleEn == 'Annual Rent Report'){
            element.allow = true; 
            // if(res.permission?.pmsFeesReport_page && res.permission?.pmsFeesReport_page.includes('SHOW')){
            // }
          }

          if(element.titleEn == 'Units Report'){
            element.allow = true; 
            // if(res.permission?.pmsFeesReport_page && res.permission?.pmsFeesReport_page.includes('SHOW')){
            // }
          }

          if(element.titleEn == 'Avaliable Units'){
            element.allow = true;
            // if(res.permission?.pmsFeesReport_page && res.permission?.pmsFeesReport_page.includes('SHOW')){
            // }
          }

          if(element.titleEn == 'Dues Entitlements'){
            element.allow = true;
            // if(res.permission?.pmsFeesReport_page && res.permission?.pmsFeesReport_page.includes('SHOW')){
            // }
          }
          
        });
      }
    });
    this.menuItems = this.menuService.getHorizontalMenuItems();
    this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId);
  }

}