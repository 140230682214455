import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class OwnersService {

  REST_API: string = APP_CONFIG.API_ENDPOINT;
  API_URL = `${this.REST_API}`;
  REST_No_API: string = APP_CONFIG.NO_API_ENDPOINT;
  NO_API_URL = `${this.REST_No_API}`;

  constructor(private http: HttpClient) { }


  getAllOwners(): Observable<any> {
    return this.http.get(this.API_URL+'/owners/findallOwners')
      .pipe(
        catchError(this.handleError)
      )
  }

  getOwnersInkeyUp(data): Observable<any> {
    return this.http.get(this.API_URL+'/owners/searchInOwnerName'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllOwnersForPMSProperty(data): Observable<any> {
    return this.http.get(this.API_URL+'/owners/findRealEstateOwners'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getAttachements(data): Observable<any> {
    return this.http.get(this.API_URL+'/owners/findOwnerDocs'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  addOwnerDocs(data): Observable<any> {
    return this.http.post(this.API_URL+'/owners/addOwnerDoc',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteOwnerAttachement(data): Observable<any> {
    return this.http.post(this.API_URL+'/owners/deleteOwnerDoc',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteTaxAttachement(data): Observable<any> {
    return this.http.post(this.API_URL+'/owners/deleteOwnerTaxNumberDoc',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteLegalAttachement(data): Observable<any> {
    return this.http.post(this.API_URL+'/legalRepresentatives/deleteLrDoc',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  changeOwnerStatus(data: any): Observable<any>{
    return this.http.post(this.API_URL+'/owners/updateOwnerStatus',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteOwner(data: any): Observable<any>{
    return this.http.post(this.API_URL+'/owners/deleteOwner',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getManulData(): Observable<any> {
    return this.http.get(this.API_URL+'/manualDatas/findManualData')
      .pipe(
        catchError(this.handleError)
      )
  }

  addOwner(data): Observable<any> {
    return this.http.post(this.API_URL+'/owners/createOwnerNG',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateOwner(data): Observable<any> {
    return this.http.post(this.API_URL+'/owners/updateOwnerNG',data)
      .pipe(
        catchError(this.handleError)
      )
  }
  
  getOwnerTaxNumber(data): Observable<any> {
    return this.http.get(this.API_URL+'/owners/findAllOwnerTaxNumber'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

  getOwnerActiveTaxNumber(data): Observable<any> {
    return this.http.get(this.API_URL+'/owners/findActiveOwnerTaxNumber'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

  addOwnerTaxNumber(data): Observable<any> {
    return this.http.post(this.API_URL+'/owners/saveOwnerTaxNumberNG',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  changeOwnerTaxNumberStatus(data: any): Observable<any>{
    return this.http.post(this.API_URL+'/owners/updateStatusOwnerTaxNumber',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  addTaxNumberDocument(data): Observable<any> {
    return this.http.post(this.API_URL+'/owners/createOwnerTaxNumberDoc', data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteOwnerTAxNumber(data): Observable<any> {
    // return this.http.post(this.NO_API_URL+'/propertyManagement/deleteOTN', data)
    return this.http.post(this.API_URL+'/owners/deleteOwnerTaxNumber', data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getOwnerLegalRepresentive(data): Observable<any> {
    return this.http.get(this.API_URL+'/legalRepresentatives/findAllByConnectionType'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  addOwnerLegal(data): Observable<any> {
    return this.http.post(this.API_URL+'/legalRepresentatives/createOwnerOrTenentLegalRepresentativeNG',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  addExistLegalRepresentative(data): Observable<any> {
    return this.http.post(this.API_URL+'/legalRepresentatives/addExistedLegalRepresentativeToOwnerOrTenant',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateOwnerLegal(data): Observable<any> {
    return this.http.post(this.API_URL+'/legalRepresentatives/updateOwnerOrTenentLegalRepresentativeNG',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getDocumentInfo(data): Observable<any> {
    return this.http.get(this.NO_API_URL+'/findTableInformationByNameAndId'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }
  
  getDocumentInfoByFiledName(data): Observable<any> {
    return this.http.get(this.NO_API_URL+'/findTableInformationByFieldNameAndId'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  addLegalDocumentTpye(data): Observable<any> {
    return this.http.post(this.API_URL+'/legalRepresentatives/createLegalRepresentationDocumentType', data)
      .pipe(
        catchError(this.handleError)
      )
  }

  addLegalDocument(data): Observable<any> {
    return this.http.post(this.API_URL+'/legalRepresentatives/addLrDocs', data)
      .pipe(
        catchError(this.handleError)
      )
  }

  changeOwnerLegalStatus(data: any): Observable<any>{
    return this.http.post(this.API_URL+data.url,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteLegalRepresntative(data: any): Observable<any>{
    return this.http.post(this.API_URL+'/tenantLegalRepresentatives/deleteLegalRepresentative',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  //Legal Representative page

  getAllLegalRepresentive(data): Observable<any> {
    return this.http.post(this.API_URL+'/legalRepresentatives/findAllLegalRepresentatives',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteLegalRepresntativeAccount(data: any): Observable<any>{
    return this.http.post(this.API_URL+'/legalRepresentatives/deleteLegalRepresentative',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getOwnerTaxNumberById(data): Observable<any> {
    return this.http.get(this.API_URL+'/owners/findOwnerTaxNumberById'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.message.includes('login')){
      localStorage.removeItem('user');
      localStorage.removeItem('sessionID');
      localStorage.removeItem('token');
      window.location.reload();
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  jsonToQueryString(json) {
    return '?' + 
        Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
  }
}
