import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class RealEstateUnitsService {

  REST_API: string = APP_CONFIG.API_ENDPOINT;
  API_URL = `${this.REST_API}`;
  REST_No_API: string = APP_CONFIG.NO_API_ENDPOINT;
  NO_API_URL = `${this.REST_No_API}`;

  constructor(private http: HttpClient) { }

  getAllRealEstateUnits(): Observable<any> {
    return this.http.get(this.NO_API_URL+'/propertyManagement/showRealEstateUnit')
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllRealEstateUnitsWithoutDatatable(): Observable<any> {
    return this.http.get(this.API_URL+'/units/findAllUnitsWithoutDatatable')
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllRealEstateUnitsDataTable(data:any): Observable<any> {
    return this.http.post(this.API_URL+'/units/findAllUnits',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllUnits(): Observable<any> {
    return this.http.get(this.API_URL+'/units/findAllUnitsNameAndIdOnly')
      .pipe(
        catchError(this.handleError)
      )
  }

  getUnitsList(data:any): Observable<any>{
    return this.http.get(this.API_URL+'/units/autoCompleteUnitName'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

  getUnitsById(data:any): Observable<any>{
    return this.http.get(this.API_URL+'/units/findOneUnitById'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

  getAllRealEstateUnitsWithPagination(data:any): Observable<any> {
    return this.http.get(this.API_URL+'/units/findAllUnits'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  addRealEstateUnit(data): Observable<any> {
    return this.http.post(this.API_URL+'/units/createUnit',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateRealEstateUnit(data): Observable<any> {
    return this.http.post(this.API_URL+'/units/updateUnit',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteRealEstateUnit(data): Observable<any> {
    return this.http.post(this.API_URL+'/units/deleteUnit',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  returnRealEstateUnit(data): Observable<any> {
    return this.http.post(this.API_URL+'/units/retrieveDeletedUnit',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllCitiesName(): Observable<any>{
    return this.http.get(this.API_URL+'/units/findAllUnitCities')
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllUnitByCityName(data:any): Observable<any>{
    return this.http.post(this.API_URL+'/units/findAllUnitsByCityName',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllUnitCompounds(): Observable<any> {
    return this.http.get(this.API_URL+'/units/findAllUnitCompounds')
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllUnitByCompoundName(data:any): Observable<any>{
    return this.http.post(this.API_URL+'/units/findAllUnitsByCompoundId',data)
      .pipe(
        catchError(this.handleError)
      )
  }
  
  getAllUnitByOwnerId(data:any): Observable<any>{
    return this.http.post(this.API_URL+'/units/findAllUnitsByOwnerId',data)
    .pipe(
      catchError(this.handleError)
    )
  }

  getAllUnitByBuildingName(data:any): Observable<any>{
    return this.http.post(this.API_URL+'/units/findAllUnitsByBuildingId',data)
      .pipe(
        catchError(this.handleError)
      )
  }
  
  getUnitAttachment(data:any): Observable<any> {
    return this.http.get(this.API_URL+'/units/findAllUnitDocs'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  
  deleteUnitsDoc(data): Observable<any> {
    return this.http.post(this.API_URL+'/units/deleteUnitDoc', data)
      .pipe(
        catchError(this.handleError)
      )
  }

  addِUnitsDoc(data): Observable<any> {
    return this.http.post(this.API_URL+'/units/createUnitDoc', data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getUnitsCountAndStatusByOwner(data): Observable<any> {
    return this.http.get(this.API_URL+'/units/findUnitsCountAndStatusByOwnerIdReport'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getAvaliableUnitsByOwnerId(data): Observable<any> {
    return this.http.get(this.API_URL+'/units/findAllAvailableUnitsByOwnerIdReport'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }
  findAllDuesForOwnersByDate(data): Observable<any> {
    return this.http.get(this.API_URL+'/dues/findAllDuesForOwnersByDate'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.message.includes('login')){
      localStorage.removeItem('user');
      localStorage.removeItem('sessionID');
      localStorage.removeItem('token');
      window.location.reload();
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  jsonToQueryString(json) {
    return '?' + 
        Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
  }

}
