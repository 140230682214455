import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { MomentDateModule } from '@angular/material-moment-adapter';
import {MatTreeModule} from '@angular/material/tree';
import { TranslateModule } from '@ngx-translate/core';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { AppLoaderService } from './app-loader/app-loader.service';
import { AppConfirmComponent } from './app-confirm/app-confirm.component';
import { AppConfirmService } from './app-confirm/app-confirm.service';
import { DataTablesModule } from "angular-datatables";

import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { AgmCoreModule } from '@agm/core';
// import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { SheardDirectivesModule } from 'src/app/directives/sheard-directives.module';
import { NgxHijriGregorianDatepickerModule } from 'ngx-hijri-gregorian-datepicker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActionMenuComponent } from './action-menu/action-menu.component';
import { TenantsDuesComponent } from '../pages/propertyManagementAccount/tenants-dues/tenants-dues.component';
import { TenantAttachmenetComponent } from '../pages/propertyManagementAccount/tenants/tenant-attachmenet/tenant-attachmenet.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MomentDateModule,
    MatTreeModule,
    TranslateModule,
    DataTablesModule,
    NgImageFullscreenViewModule,
    NgxMatSelectSearchModule,
    // MatGoogleMapsAutocompleteModule,
    SheardDirectivesModule,
    NgxHijriGregorianDatepickerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDuj0rJ92C-apVZyadUUInMiVNsLCAegzk',
      libraries: ['places'],
      language: 'ar',
      region: 'AR'
    }),
    InfiniteScrollModule,
  ],
  exports: [
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    // MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    TranslateModule,
    ContentHeaderComponent,
    BreadcrumbComponent,
    AppLoaderComponent,
    AppConfirmComponent,
    DataTablesModule,
    NgImageFullscreenViewModule,
    NgxMatSelectSearchModule,
    // MatGoogleMapsAutocompleteModule,
    SheardDirectivesModule,
    AgmCoreModule,
    NgxHijriGregorianDatepickerModule,
    InfiniteScrollModule,
    FilterMenuComponent,
    ActionMenuComponent,
    FormsModule,
    ReactiveFormsModule,
    TenantsDuesComponent,
    TenantAttachmenetComponent
  ],
  declarations: [
    ContentHeaderComponent,
    BreadcrumbComponent,
    AppLoaderComponent,
    AppConfirmComponent,
    FilterMenuComponent,
    ActionMenuComponent,
    TenantsDuesComponent,
    TenantAttachmenetComponent,
  ],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS},AppLoaderService, AppConfirmService
  ],
})
export class SharedModule { }
