import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate{
  constructor(private loginService: LoginService, private router: Router){}
  
  canActivate( route: ActivatedRouteSnapshot){
    return this.loginService.getUser().pipe(
      filter(val=> val !== null),
      take(1),
      map(user=>{
        if(!user){
          return this.router.parseUrl('/login');
        }else{
          this.loginService.updateUserPremission(user);
          var url = route.data?.breadcrumb;
          if(user.isAdmin == 0 || user.isAdmin == false){
            if(url == 'users' && !user.permission?.propertyManagementUsers_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'pms Settings' && !user.permission?.propertyManagerSettings_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'financialAccountsTree' && !user.permission?.showFinancialAccountsTree_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Account statement' && !user.permission?.showFinancialAccountsTree_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Fincance Statement' && !user.permission?.financialRestrictions_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Bank Accounts' && !user.permission?.bankAccounts_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Owners' && !user.permission?.owners_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Compounds' && !user.permission?.compounds_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Buildings' && !user.permission?.buildings_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Real Estate Units' && !user.permission?.realEstateUnits_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Meter Service' && !user.permission?.metersService_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Accessories And Fittings' && !user.permission?.accessoriesAndFittings_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Tenants' && !user.permission?.tenants_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Rent Agreements' && !user.permission?.rentAgreements_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Tenants Dues' && !user.permission?.tenantsDues_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Reciept Voucher' && !user.permission?.receiptVoucher_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Pay Tenants Dues' && !user.permission?.payTenantsDues_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Follow Up On Collections' && !user.permission?.trackingDues_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Trail Balance' && !user.permission?.trialBalance_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else if(url == 'Contact With Tenants' && !user.permission?.tenants_page.includes('SHOW')){
              return this.router.parseUrl('/error');
            }else{
              return true;
            }
          }else{
            return true;
          }
        }
      })
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad( route: ActivatedRouteSnapshot){
    return this.loginService.getUser().pipe(
      filter(val=> val !== null),
      take(1),
      map(user=>{
        if(user){          
          return this.router.parseUrl('/');
        }else{
          return true;
        }
      })
    );
  }
}
