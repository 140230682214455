import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
    { 
        path: '',
        component: PagesComponent, children: [
            { 
                path: '', 
                loadChildren: () =>
                import('./pages/dashboard/dashboard.module').then(
                    (m) => m.DashboardModule
                ),
                data: { breadcrumb: 'Dashboard' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'users', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/pmauser/pmauser.module').then(
                    (m) => m.PMAUserModule
                ),
                data: { breadcrumb: 'users' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'pmsSettings', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/pms-setting/pms-setting.module').then(
                    (m) => m.PmsSettingModule
                ),
                data: { breadcrumb: 'pms Settings' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'financialAccountsTree', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/financial-accounts-tree/financial-accounts-tree.module').then(
                    (m) => m.FinancialAccountsTreeModule
                ),
                data: { breadcrumb: 'Financial accounts tree' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'accountStatement', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/account-statement/account-statement.module').then(
                    (m) => m.AccountStatementModule
                ),
                data: { breadcrumb: 'Account statement' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'financeStatement', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/finance-statement/finance-statement.module').then(
                    (m) => m.FinanceStatementModule
                ),
                data: { breadcrumb: 'Fincance Statement' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'bankAccounts', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/bank-accounts/bank-accounts.module').then(
                    (m) => m.BankAccountsModule
                ),
                data: { breadcrumb: 'Bank Accounts' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'owners', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/owners/owners.module').then(
                    (m) => m.OwnersModule
                ),
                data: { breadcrumb: 'Owners' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'compounds', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/compounds/compounds.module').then(
                    (m) => m.CompoundsModule
                ),
                data: { breadcrumb: 'Compounds' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'buildings', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/buildings/buildings.module').then(
                    (m) => m.BuildingsModule
                ),
                data: { breadcrumb: 'Buildings' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'realEstateUnits', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/real-estate-units/real-estate-units.module').then(
                    (m) => m.RealEstateUnitsModule
                ),
                data: { breadcrumb: 'Real Estate Units' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'meterService', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/meters-service/meters-service.module').then(
                    (m) => m.MetersServiceModule
                ),
                data: { breadcrumb: 'Meter Service' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'accessoriesAndFittings', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/accessories-and-fittings/accessories-and-fittings.module').then(
                    (m) => m.AccessoriesAndFittingsModule
                ),
                data: { breadcrumb: 'Accessories And Fittings' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'tenants', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/tenants/tenants.module').then(
                    (m) => m.TenantsModule
                ),
                data: { breadcrumb: 'Tenants' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'rentAgreements', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/rent-agreements/rent-agreements.module').then(
                    (m) => m.RentAgreementsModule
                ),
                data: { breadcrumb: 'Rent Agreements' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'tenantsDues', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/tenants-dues/tenants-dues.module').then(
                    (m) => m.TenantsDuesModule
                ),
                data: { breadcrumb: 'Tenants Dues' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'receiptVoucher', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/receipt-voucher/receipt-voucher.module').then(
                    (m) => m.ReceiptVoucherModule
                ),
                data: { breadcrumb: 'Reciept Voucher' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'payTenantsDues', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/tenant-dues-paymenets/tenant-dues-paymenets.module').then(
                    (m) => m.TenantDuesPaymenetsModule
                ),
                data: { breadcrumb: 'Pay Tenants Dues' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'handingUnit', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/handing-units/handing-units.module').then(
                    (m) => m.HandingUnitsModule
                ),
                data: { breadcrumb: 'Handing Units' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'followUpOnCollections', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/follow-up-on-conllections/follow-up-on-conllections.module').then(
                    (m) => m.FollowUpOnConllectionsModule
                ),
                data: { breadcrumb: 'Follow Up On Collections' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'trailBalance', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/trial-balance/trial-balance.module').then(
                    (m) => m.TrialBalanceModule
                ),
                data: { breadcrumb: 'Trail Balance' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'rentalStatistics', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/rental-statistics/rental-statistics.module').then(
                    (m) => m.RentalStatisticsModule
                ),
                data: { breadcrumb: 'Rental Statistics' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'contactWithTenants', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/contact-with-tenants/contact-with-tenants.module').then(
                    (m) => m.ContactWithTenantsModule
                ),
                data: { breadcrumb: 'Contact With Tenants' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'dueNotifications', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/due-notifications/due-notifications.module').then(
                    (m) => m.DueNotificationsModule
                ),
                data: { breadcrumb: 'Due Notification List' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'AllLegalRepresentatives', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/legal-representatives/legal-representatives.module').then(
                    (m) => m.LegalRepresentativesModule
                ),
                data: { breadcrumb: 'All Legal Representatives' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'userProfile', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/user-profile/user-profile.module').then(
                    (m) => m.UserProfileModule
                ),
                data: { breadcrumb: 'User Profile' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'summary', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/summary/summary.module').then(
                    (m) => m.SummaryModule
                ),
                data: { breadcrumb: 'Summary' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'pmsFeesReport', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/pms-fees-report/pms-fees-report.module').then(
                    (m) => m.PmsFeesReportModule
                ),
                data: { breadcrumb: 'PMS Fees Report' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'securityDepositReport', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/pms-fees-report/pms-fees-report.module').then(
                    (m) => m.PmsFeesReportModule
                ),
                data: { breadcrumb: 'Security Deposit Report' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'annualRentReport', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/pms-fees-report/pms-fees-report.module').then(
                    (m) => m.PmsFeesReportModule
                ),
                data: { breadcrumb: 'Annual Rent Report' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'reports', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/reports/reports.module').then(
                    (m) => m.ReportsModule
                ),
                data: { breadcrumb: 'Reports' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'tenantProfile', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/tenant-profile/tenant-profile.module').then(
                    (m) => m.TenantProfileModule
                ),
                data: { breadcrumb: 'Tenant Profile' },
                canActivate:[AuthGuard]
            },
            { 
                path: 'publicServiceCompanies', 
                loadChildren: () =>
                import('./pages/propertyManagementAccount/public-service-companies/public-service-companies.module').then(
                    (m) => m.PublicServiceCompaniesModule
                ),
                data: { breadcrumb: 'Public Service Companies' },
                canActivate:[AuthGuard]
            },
        ]
    },
    { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
    { 
        path: 'login', 
        loadChildren: () => import('./pages/login/login.module').then( m => m.LoginModule),
        canLoad:[AuthGuard]
    },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    { path: '**', redirectTo: 'dashboard', }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules // <- comment this line for activate lazy load
    //    useHash: true
    ,
    relativeLinkResolution: 'legacy'
});