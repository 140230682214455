import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './app-confirm.component.html',
  styleUrls: ['./app-confirm.component.scss'],
})
export class AppConfirmComponent {
  selectedRemoveOption: string;
  removeOptions: { key: string; value: string }[] = [];

  constructor(
    public dialogRef: MatDialogRef<AppConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService
  ) {
    this.removeOptions = [
      {
        key: 'justOne',
        value:
          data.mode === 'REMOVE'
            ? this.translateService.instant(
                'DELETE_DOCTOR_AVAILABILITY_POPUP_JUST_ONE'
              )
            : this.translateService.instant(
                'EDIT_DOCTOR_AVAILABILITY_POPUP_JUST_ONE'
              ),
      },
      {
        key: 'entireSeries',
        value:
          data.mode === 'REMOVE'
            ? this.translateService.instant(
                'DELETE_DOCTOR_AVAILABILITY_POPUP_ENTIRE_SERIES'
              )
            : this.translateService.instant(
                'EDIT_DOCTOR_AVAILABILITY_POPUP_ENTIRE_SERIES'
              ),
      },
    ];
  }
}
