import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LanguageService } from '../../../services/language.service';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { ThemeSettingsService } from '../../../services/theme-settings/theme-settings.service';

@Component({
  selector: 'app-flags-menu',
  templateUrl: './flags-menu.component.html',
  styleUrls: ['./flags-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FlagsMenuComponent implements OnInit {

  public settings: Settings;
  constructor(public appSettings:AppSettings,private themeSettingsService: ThemeSettingsService,private languageService: LanguageService
    ){
      this.settings = this.appSettings.settings; 
  }

  ngOnInit() {
  }

  changeLanguage(lan){
    this.languageService.setLanguage(lan);
    if(lan == 'ar'){
      this.settings.rtl = true;
    }else{
      this.settings.rtl = false;
    }
    this.themeSettingsService.setThemeSettings(this.settings)
  }
}
