import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from '../../../app.config';
import { User } from '../../../interfaces/propertyManagement/users';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { 
  }

  REST_API: string = APP_CONFIG.API_ENDPOINT;
  API_URL = `${this.REST_API}/propertyManagementAccountUsers`;

  getPmaUsers(): Observable<any> {
    return this.http.get(this.API_URL+'/findAllPropertyManagementAccountUsers')
      .pipe(
        catchError(this.handleError)
      )
  }

  getUserDocs(data:any): Observable<any> {
    return this.http.get(this.API_URL+'/findAllPropertyManagementAccountUserDocs'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  addUser(user: User): Observable<any>{
    return this.http.post(this.API_URL+'/createPropertyManagementAccountUser',user)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateUser(user: User): Observable<any>{
    return this.http.post(this.API_URL+'/updatePropertyManagementAccountUserNG',user)
      .pipe(
        catchError(this.handleError)
      )
  }

  changeStatus(data: any): Observable<any>{
    return this.http.put(this.API_URL+'/changeStatusPropertyManagementAccountUserNG/'+data.id,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteUser(user: User): Observable<any>{
    return this.http.delete(this.API_URL+'/deletePropertyManagementAccountUserNG/'+user.id)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteUserDoc(data: any): Observable<any>{
    return this.http.post(this.API_URL+'/deletePropertyManagementAccountUserDoc',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  sendEmailMessage(value: any): Observable<any>{
    return this.http.post(this.REST_API+'/sendEmails/sendEmail',value)
      .pipe(
        catchError(this.handleError)
      )
  }

  getUserPermission(data): Observable<any> {
    return this.http.get(this.REST_API+'/propertyManagementAccountUserPermissions/findPermissionByPropertyManagementAccountUserPermission'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  addUserPermission(data): Observable<any> {
    return this.http.post(this.REST_API+'/propertyManagementAccountUserPermissions/createPermissionForPropertyManagementAccountUser',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  saveUserFiles(data): Observable<any> {
    return this.http.post(this.API_URL+'/createPropertyManagementAccountUserDoc',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateUserProfilePic(data): Observable<any> {
    return this.http.post(this.API_URL+'/setPersonalPhotoLink',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateUserProfile(data): Observable<any> {
    return this.http.post(this.API_URL+'/updatePropertyManagementAccountUserProfileData',data)
      .pipe(
        catchError(this.handleError)
      )
  }
  
  // Error 
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.message.includes('login')){
      localStorage.removeItem('user');
      localStorage.removeItem('sessionID');
      localStorage.removeItem('token');
      window.location.reload();
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  jsonToQueryString(json) {
    return '?' + 
        Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
  }
}
