import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG } from '../../../app.config';
import { LoginService } from './../../../services/login.service';
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  
  constructor(private loginService: LoginService,private router: Router) { }
  currentUser = this.loginService.getCurrentUser();
  REST_No_API: string = APP_CONFIG.NO_API_ENDPOINT;
  NO_API_URL = `${this.REST_No_API}`;
  ngOnInit() {
  }
  logOut(){
    this.loginService.logOut().subscribe(res=>{
      if(res && res.msg == 'done'){
        this.loginService.destoryStorage();
        window.location.href = '/#/login'
        window.location.reload();
      }
    });
  }
}
