import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { APP_CONFIG } from 'src/app/app.config';
import { ContactWithTenantsService } from 'src/app/services/PMAServices/contact-with-tenants/contact-with-tenants.service';

@Component({
  selector: 'app-connect-to-whatsapp',
  templateUrl: './connect-to-whatsapp.component.html',
  styleUrls: ['./connect-to-whatsapp.component.scss']
})
export class ConnectToWhatsappComponent implements OnInit {
  REST_No_API: string = APP_CONFIG.NO_API_ENDPOINT;
  QRScand: any;
  url: any;

  constructor(private sanitizer: DomSanitizer,public dialogRef: MatDialogRef<ConnectToWhatsappComponent>,) { }

  ngOnInit(): void {
    this.url =  this.sanitizer.bypassSecurityTrustResourceUrl(this.REST_No_API+'/auth/getqr');
  }

  regerateConnectionQR(){
    this.dialogRef.close('regenerate');
  }

}
