import { Component, OnInit, ViewChild } from '@angular/core';
import { ContactWithTenantsService } from '../../../services/PMAServices/contact-with-tenants/contact-with-tenants.service';
import { Observable } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { OwnersService } from 'src/app/services/PMAServices/owners/owners.service';
import { CompoundService } from 'src/app/services/PMAServices/compound/compound.service';
import { BuildingService } from 'src/app/services/PMAServices/building/building.service';
import { map, startWith } from 'rxjs/operators';
import { RealEstateUnitsService } from 'src/app/services/PMAServices/real-estate-units/real-estate-units.service';
import { TenantsService } from 'src/app/services/PMAServices/tenants/tenants.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ConnectToWhatsappComponent } from './connect-to-whatsapp/connect-to-whatsapp.component';
import { AppLoaderService } from 'src/app/shared/app-loader/app-loader.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
// import * as internal from 'assert';

@Component({
  selector: 'app-contact-with-tenants',
  templateUrl: './contact-with-tenants.component.html',
  styleUrls: ['./contact-with-tenants.component.scss']
})
export class ContactWithTenantsComponent implements OnInit {
  @ViewChild(NgbAccordion) cards: NgbAccordion;
  form:UntypedFormGroup;
  formMessage:UntypedFormGroup;
  formDueNotification: UntypedFormGroup;
  showFiltered: boolean = false;
  compoundList: any;
  BuildingList: any;
  ownersList: any;
  isWhatsAppOnline: boolean = false;
  allTenant: boolean = true;

  filteredControl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<any[]>;
  options: any[]=[];

  filteredControlTenant: UntypedFormControl = new UntypedFormControl();
  filteredOptionsTenant: Observable<any[]>;
  optionsTenant: any[]=[];
  
  filteredMessageOptions: Observable<any[]>;
  optionsMessages: any = [];

  months: any[] = [
    {id: '1', name: 'January'},
    {id: '2', name: 'February'},
    {id: '3', name: 'March'},
    {id: '4', name: 'April'},
    {id: '5', name: 'May'},
    {id: '6', name: 'June'},
    {id: '7', name: 'July'},
    {id: '8', name: 'August'},
    {id: '9', name: 'September'},
    {id: '10', name: 'October'},
    {id: '11', name: 'November'},
    {id: '12', name: 'December'},
  ];

  countDays: number = 30;

  @ViewChild(MatMenuTrigger) filterMenu: MatMenuTrigger;

  successMessageSent: any;

  constructor(public fb: UntypedFormBuilder, 
    private ownersService: OwnersService, 
    private loader: AppLoaderService,
    public dialog: MatDialog,
    private buildingService: BuildingService,
    private realEstateUnitsService: RealEstateUnitsService,
    private tenantsService: TenantsService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private contactWithTenantsService: ContactWithTenantsService,
    private compoundInfosService: CompoundService) { }

  ngOnInit(): void {

    this.checkWhatsappConnection();

    this.form = this.fb.group({
      compoundId:'',
      buildingId:'',
      unitId: '',
      tenantId: '',
    });

    this.formMessage = this.fb.group({
      allTenant:true,
      contactType: "MESSAGE",
      messageType: "SMS",
      messageText: ['', Validators.compose([Validators.required])],
      filterOptions: [],
      messageSampleId: ''
    });

    this.formDueNotification = this.fb.group({
      activstionStatus: ['', Validators.compose([Validators.required])],
      month: '',
      day: '',
      hour: '',
      minute: '',
    });

    this.compoundInfosService.getCompoundForSelect().subscribe(res=>{
      if(res){
        this.compoundList = res;
      }
    });

    this.buildingService.getAllBuildings().subscribe(res=>{
      if(res){
        this.BuildingList = res;
      }
    });

    this.filteredOptions = this.filteredControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.fullName),
      map(fullName => fullName ? this._filter(fullName) : this.options.slice())
    );

    this.filteredOptionsTenant = this.filteredControlTenant.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.fullName),
      map(fullName => fullName ? this._filterTenant(fullName) : this.optionsTenant.slice())
    );

    this.filteredMessageOptions = this.formMessage.get('messageText').valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.messageText),
      map(messageText => messageText ? this._filterMessage(messageText) : this.optionsMessages.slice())
    );

  }

  private _filter(fullName: string): any[] {
    const filterValue = fullName;
    return this.options.filter(option => option.fullName.toLowerCase().includes(filterValue));
  }

  private _filterTenant(fullName: string): any[] {
    const filterValue = fullName;
    return this.optionsTenant.filter(option => option.fullName.toLowerCase().includes(filterValue));
  }

  displayFnTenant(name: any): string {
    return name && name.fullName ? name.fullName : '';
  }

  private _filterMessage(messageText: string): any[] {
    const filterValue = messageText;
    return this.optionsMessages.filter(option => option.messageText.toLowerCase().includes(filterValue));
  }

  displayFnMessage(name: any): string {
    return name && name.messageText ? name.messageText : '';
  }

  getUnitsList(val){
    var data = {searchPart:''};
    data.searchPart = val.target.value;
    this.realEstateUnitsService.getUnitsList(data).subscribe(res=>{
      if(res){
        res.forEach(element => {
          var name = '';
          if(element.compound){
              if(element.building){
                  name = element.compound.name+"-"+element.building.name+"-"+element.name;
              }else{
                  name = element.compound.name+"-"+element.name;
              }
          }
          if(element.building){
              name = element.building.name+"-"+element.name
          }
        
          if(!element.building && !element.compound){
              name = element.name;
          }
          element.fullName = name;
        });
        this.options = res;
      }
    })
  }

  getTenantsList(name: any){
    var data = {searchPart: ''};
    data.searchPart = name.target.value;
    this.tenantsService.searchForTenantWithName(data).subscribe(res=>{
      if(res.length != 0){
        res.forEach(element => {
          if(element.firstName){
            var autoVal =`${element.firstName+' '+element.fatherName+' '+element.grandfatherName+' '+element.lastName}`;
        }else{
            var autoVal =`${element.entityName}`;
        } 
          element.fullName = autoVal;
        });
        this.optionsTenant = res;
      }
    });
  }

  getMessagesList(name: any){
    var data = {searchPart: ''};
    data.searchPart = name.target.value;
    this.contactWithTenantsService.searchForMessageText(data).subscribe(res=>{
      console.log(res);
      if(res.length != 0){
        this.optionsMessages = res;
      }
    });
  }

  compoundSelected(){
    this.form.patchValue({
        buildingId:'',
        unitId: '',
        tenantId: '',
    });

  }

  buildingSelected(){
    this.form.patchValue({
      compoundId:'',
      unitId: '',
      tenantId: '',
    });
  }

  OnSelectedUnit(event){
    this.form.patchValue({
      unitId: event.value.id,
      compoundId:'',
      buildingId:'',
      tenantId: '',
    });
  }

  OnSelectedTenant(event){
    this.form.patchValue({
      unitId: '',
      compoundId:'',
      buildingId:'',
      tenantId: event.value.id,
    });
  }

  OnSelectedMessage(event){
    this.formMessage.patchValue({
      messageSampleId: event.value.id
    });
  }

  closeFilteredData(){
    this.showFiltered = false;
    this.form.reset();
  }

  toggleAllCard(){
    this.cards.collapseAll()
  }

  closeFilterMenu(){
    this.filterMenu.closeMenu();
  }

  showExistLegalRepresentive(event){
    if(event.checked){

      this.formMessage.patchValue({
        allTenant: true
      });

      this.form.patchValue({
        unitId: '',
        compoundId:'',
        buildingId:'',
        tenantId: '',
      });
    }
  }

  submit(){
    if(this.form.valid){
      if(this.form.value.compoundId != '' || this.form.value.buildingId != '' || this.form.value.unitId != '' || this.form.value.tenantId != ''){
        this.formMessage.patchValue({
          allTenant: false,
          filterOptions: this.form.value 
        });
      }
      this.closeFilterMenu();
    }
  }

  submitMessage(){
    if(this.formMessage.valid){
      if(this.formMessage.value.messageText.id){
        this.formMessage.patchValue({
          messageText: this.formMessage.value.messageText.messageText
        });
      }
      if(this.formMessage.value.contactType == 'MESSAGE'){
        if(this.formMessage.value.messageType == 'WHATSAPP' && !this.isWhatsAppOnline ){
          this.snackBar.open(this.translate.instant('PLEASE_CONNECT_TO_WHATSAPP'), this.translate.instant('ERROR'), { duration: 4000 });
          return false;
        }
      }
      if(this.formMessage.value.messageType == 'WHATSAPP'){
        this.loader.open();
        this.contactWithTenantsService.sendWhatsAppMessageOrCall(this.formMessage.value).subscribe(res=>{
          this.loader.close();
          if(res){
            this.snackBar.open(this.translate.instant('SENT_SUCCESS_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });  
            this.successMessageSent = res.data;
            setTimeout(() => {
              this.successMessageSent = [];
            }, 4000);
            this.formMessage.reset();
          }
          console.log(res);
        },err=>{
          this.loader.close();
          this.snackBar.open(this.translate.instant('ERROR_MESSAGE'), this.translate.instant('ERROR'), { duration: 4000 });
        });
      }
      
    }
  }

  dueNotificationSelected(event: any){
    
    if(event.value == 'ON'){
      console.log('here:',event.value);
      this.formDueNotification.controls['month'].setValidators([Validators.required]);
      this.formDueNotification.controls['month'].updateValueAndValidity();
      this.formDueNotification.controls['day'].setValidators([Validators.required]);
      this.formDueNotification.controls['day'].updateValueAndValidity();
      this.formDueNotification.controls['hour'].setValidators([Validators.required]);
      this.formDueNotification.controls['hour'].updateValueAndValidity();
      this.formDueNotification.controls['minute'].setValidators([Validators.required]);
      this.formDueNotification.controls['minute'].updateValueAndValidity();
    } else if(event.value == 'OFF'){
      console.log('there:',event.value);
      this.formDueNotification.controls["month"].clearValidators();
      this.formDueNotification.controls['month'].updateValueAndValidity();

      this.formDueNotification.controls["day"].clearValidators();
      this.formDueNotification.controls['day'].updateValueAndValidity();

      this.formDueNotification.controls["hour"].clearValidators();
      this.formDueNotification.controls['hour'].updateValueAndValidity();

      this.formDueNotification.controls["minute"].clearValidators();
      this.formDueNotification.controls['minute'].updateValueAndValidity();
    }
  }

  submitDueNotificationStatus(){
    if(this.formDueNotification.valid){
      console.log(this.formDueNotification.value);
      return;
      if(this.formDueNotification.value.activstionStatus == 'ON'){
        if(this.formDueNotification.value.month && this.formDueNotification.value.day && this.formDueNotification.value.hour && this.formDueNotification.value.minute){
          this.contactWithTenantsService.sendAutomaticDuesNotificationsToTenants(this.formDueNotification.value).subscribe(res=>{
            this.loader.close();
            if(res){
              this.snackBar.open(this.translate.instant('SENT_SUCCESS_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });  
              this.successMessageSent = res.data;
              setTimeout(() => {
                this.successMessageSent = [];
              }, 4000);
              this.formDueNotification.reset();
            }
            console.log(res);
          },err=>{
            this.loader.close();
            this.snackBar.open(this.translate.instant('ERROR_MESSAGE'), this.translate.instant('ERROR'), { duration: 4000 });
          });
        }
      } else if(this.formDueNotification.value.activstionStatus == 'OFF'){
        
      }

      return;

      if(this.formDueNotification.value.activstionStatus == 'OFF'){
        this.loader.open();
        this.contactWithTenantsService.sendWhatsAppMessageOrCall(this.formDueNotification.value).subscribe(res=>{
          this.loader.close();
          if(res){
            this.snackBar.open(this.translate.instant('SENT_SUCCESS_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });  
            this.successMessageSent = res.data;
            setTimeout(() => {
              this.successMessageSent = [];
            }, 4000);
            this.formDueNotification.reset();
          }
          console.log(res);
        },err=>{
          this.loader.close();
          this.snackBar.open(this.translate.instant('ERROR_MESSAGE'), this.translate.instant('ERROR'), { duration: 4000 });
        });
      } 
    }
  }
  
  checkWhatsappConnection(){
    this.contactWithTenantsService.testWhatsAppConnection().subscribe(res=>{
      if(res && res.data == 'CONNECTED'){
        this.isWhatsAppOnline = true;
      }else{
        this.isWhatsAppOnline = false;
      }
      if(res.msg == 'error'){
        this.disconnectSession()        
      }
    },err=>{
      this.isWhatsAppOnline = false;
    })
  }

  disconnectSession(){
    console.log('enter discon')
    this.contactWithTenantsService.deleteWhatsappSession().subscribe(res=>{
      console.log(res);
    });
  }
  
  connectWhatsapp(){
    let dialogRef = this.dialog.open(ConnectToWhatsappComponent, {
        data: '',
        width: '100%',
        autoFocus: false,
        maxHeight: '90vh',
    });
    dialogRef.afterClosed().subscribe(data => {
      if(data == 'regenerate'){
        this.disconnectSession();
        this.connectWhatsapp()
      }else{
        this.checkWhatsappConnection();
      }
    });
  }

  getDaysInMonth(month: any){
    if(month.value !='ALL') {
      var year = (new Date()).getFullYear();
      this.countDays = new Date(year, month.value, 0).getDate();
    } else {
      // console.log('fff11');
    }
  }
  
}

