import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class TenantsService {
  REST_API: string = APP_CONFIG.API_ENDPOINT;
  API_URL = `${this.REST_API}`;
  REST_No_API: string = APP_CONFIG.NO_API_ENDPOINT;
  NO_API_URL = `${this.REST_No_API}`;

  constructor(private http: HttpClient) { }

  getAllTenants(data:any): Observable<any> {
    return this.http.post(this.API_URL+'/tenants/findAllTenantsDataTable',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  addTenant(data): Observable<any> {
    return this.http.post(this.API_URL+'/tenants/createTenantNG',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateTenant(data): Observable<any> {
    return this.http.post(this.API_URL+'/tenants/updateTenantNG',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  tenantsCallNumbers(data): Observable<any> {
    return this.http.post(this.API_URL+'/tenantCallNumbers/createTenantCallNumber',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  showContactsList(data): Observable<any> {
    return this.http.get(this.API_URL+'/tenantCallNumbers/findAllTenantCallNumberByTenantId'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteContactNumber(data): Observable<any> {
    return this.http.post(this.API_URL+'/tenantCallNumbers/deleteTenantCallNumber',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getAttachements(data): Observable<any> {
    return this.http.get(this.API_URL+'/tenants/findAllDocsOfTenant'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getTenantById(data): Observable<any> {
    return this.http.get(this.API_URL+'/tenants/findOneTenant'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }
  
  deleteTenantAttachement(data): Observable<any> {
    return this.http.post(this.API_URL+'/tenants/deleteTenantDocs',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  addTenantDocs(data): Observable<any> {
    return this.http.post(this.API_URL+'/tenants/createTenantDoc',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  changeTenantStatus(data): Observable<any> {
    return this.http.post(this.API_URL+data.url,data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteTenant(data): Observable<any> {
    return this.http.post(this.API_URL+'/tenants/deleteTenant',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  searchForTenantWithName(data): Observable<any> {
    return this.http.get(this.API_URL+'/tenants/autoCompleteTenantName'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  changeSendDueNotificationByTenantId(data): Observable<any> {
    return this.http.get(this.API_URL+'/tenants/changeSendDueNotificationByTenantId'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getLegalRepresentative(data): Observable<any> {
    return this.http.get(this.API_URL+'/legalRepresentatives/tenentLegalRepresentativeAutoComplete'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllTenantWithLegalRepresentativeId(data): Observable<any> {
    return this.http.get(this.API_URL+'/tenants/findAllTenantsByLegalRepresentativeId'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  getLegalRepresentativeAutoComplete(data): Observable<any> {
    return this.http.get(this.API_URL+'/legalRepresentatives/legalRepresentativeAutoComplete'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  findTenantsNameArrayByIds(data): Observable<any> {
    console.log(data.tenantIdsArray);
    let params = new HttpParams();
    params = params.append('tenantIdsArray',data.tenantIdsArray);
    return this.http.get(this.API_URL+'/tenants/findTenantsNameArrayByIds',{params: params})
      .pipe(
        catchError(this.handleError)
      )
  }

  changeTenantNotificationStatus(data): Observable<any> {
    return this.http.post(this.API_URL+'/tenants/changeSendDueNotificationByTenantId',data)
    .pipe(
      catchError(this.handleError)
    )
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.message.includes('login')){
      localStorage.removeItem('user');
      localStorage.removeItem('sessionID');
      localStorage.removeItem('token');
      window.location.reload();
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  jsonToQueryString(json) {
    return '?' + 
        Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
  }
}
