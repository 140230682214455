import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BuildingService } from 'src/app/services/PMAServices/building/building.service';
import { CompoundService } from 'src/app/services/PMAServices/compound/compound.service';
import { OwnersService } from 'src/app/services/PMAServices/owners/owners.service';
import { RealEstateUnitsService } from 'src/app/services/PMAServices/real-estate-units/real-estate-units.service';
import { DateType } from 'ngx-hijri-gregorian-datepicker';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { DuesService } from 'src/app/services/PMAServices/dues/dues.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_DATE_FORMATS } from 'src/app/interfaces/dateFormat';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ExportsFileService } from 'src/app/services/PMAServices/exports-file/exports-file.service';


@Component({
  selector: 'app-rental-statistics',
  templateUrl: './rental-statistics.component.html',
  styleUrls: ['./rental-statistics.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    DatePipe
  ]
})
export class RentalStatisticsComponent implements OnInit {

  form:UntypedFormGroup;
  ownersList: any;
  compoundList: any;
  BuildingList: any;
  unitList: any;

  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  selectedDateType  =  DateType.Gregorian;  // or DateType.Hijri
  @ViewChild('datePickerFrom') datePickerFrom: any;
  @ViewChild('datePickerTo') datePickerTo: any;
  duesList: any;
  @ViewChild(MatMenuTrigger) filterMenu: MatMenuTrigger;
  totalPaidAmount: number = 0;
  totalRestAmount: number = 0;
  totalPaidAmountAndRest: number = 0;
  
  filteredControl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<any[]>;
  options: any[]=[];

  constructor(
    public fb: UntypedFormBuilder,
    private ownersService: OwnersService,
    private compoundInfosService: CompoundService, 
    private buildingService: BuildingService,
    private realEstateUnitsService: RealEstateUnitsService,
    private datePipe: DatePipe,
    private duesService: DuesService,
    private snack: MatSnackBar,
    private translate: TranslateService,
    private exportsFileService: ExportsFileService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      ownerId:[],
      compoundId:[],
      buildingId:[],
      unitId:[],
      startDate:['',Validators.compose([Validators.required])],
      endDate: ['',Validators.compose([Validators.required])]
    });

    this.ownersService.getAllOwners().subscribe(res=>{
      if(res){
        this.ownersList = res;
      }
    });

    this.compoundInfosService.getCompoundForSelect().subscribe(res=>{
      if(res){
        this.compoundList = res;
      }
    });

    this.buildingService.getAllBuildings().subscribe(res=>{
      if(res){
        this.BuildingList = res;
      }
    });

    this.realEstateUnitsService.getAllUnits().subscribe(res=>{
      if(res){
        this.unitList = res.data;
      }
    });

    this.filteredOptions = this.filteredControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.fullName),
      map(fullName => fullName ? this._filter(fullName) : this.options.slice())
    );
    
    this.startDate = this.setDateFunction({year: new Date().getUTCFullYear(), month:new Date().getUTCMonth(),day: new Date().getUTCDate()});
    this.endDate = this.setDateFunction({year: new Date().getUTCFullYear(), month:new Date().getUTCMonth()+1,day: new Date().getUTCDate()});
    setTimeout(() => {
      this.onDateSelectFrom();
      this.onDateSelectTo();
    }, 500);
  }

  private _filter(fullName: string): any[] {
    const filterValue = fullName;
    return this.options.filter(option => option.fullName.toLowerCase().includes(filterValue));
  }

  displayFn(name: any): string {
    return name && name.fullName ? name.fullName : '';
  }

  getUnitsList(val){
    var data = {searchPart:''};
    data.searchPart = val.target.value;
    this.realEstateUnitsService.getUnitsList(data).subscribe(res=>{
      if(res){
        res.forEach(element => {
          var name = '';
          if(element.compound){
              if(element.building){
                  name = element.compound.name+"-"+element.building.name+"-"+element.name;
              }else{
                  name = element.compound.name+"-"+element.name;
              }
          }
          if(element.building){
              name = element.building.name+"-"+element.name
          }
        
          if(!element.building && !element.compound){
              name = element.name;
          }
          element.fullName = name;
        });
        this.options = res;
      }
    });
  }
  
  ownerSelected(){
    this.form.patchValue({      
      compoundId:[],
      buildingId:[],
      unitId:[],
    });
  }

  compoundSelected(){
    this.form.patchValue({
      ownerId:[],
      buildingId:[],
      unitId:[],
    });
  }

  buildingSelected(){
    this.form.patchValue({
      ownerId:[],
      compoundId:[],
      unitId:[],
    });
  }

  OnSelectedUnit(event){
    this.form.patchValue({
      ownerId:[],
      compoundId:[],
      buildingId:[],
      unitId: event.value.id
    });
  }

  onDateSelectFrom(){
    let date = this.datePickerFrom.getSelectedDate(event);
    if(date != 'Invalid date'){
      this.form.patchValue({
        startDate: date
      });
    }
  }

  onDateSelectTo(){
    let date = this.datePickerTo.getSelectedDate(event);
    if(date != 'Invalid date'){
      this.form.patchValue({
        endDate: date
      });
    }
  }

  closeFilterMenu(){
    this.filterMenu.closeMenu();
  }

  submit(){
    if(this.form.valid){
      const dateFilterValue = this.prepareAgreementData(this.form);
      if(!dateFilterValue.unitId || !dateFilterValue.buildingId || !dateFilterValue.compoundId  || !dateFilterValue.ownerId ){
        this.snack.open(this.translate.instant('ERROR_MESSAGE_AT_LEAST_ONE'), this.translate.instant('ERROR'), { duration: 4000 });
        return;
      }
      this.totalPaidAmount = 0;
      this.totalRestAmount = 0;
      this.totalPaidAmountAndRest = 0;
      this.duesService.getRetnalStatistics(dateFilterValue).subscribe(res=>{
        this.filterMenu.closeMenu();
        if(res && res.length != 0){
          this.duesList = res;
          res.forEach(element => {
            this.totalPaidAmount += element.totalPaidAmount;
            this.totalRestAmount += element.totalRestAmount;
            this.totalPaidAmountAndRest += element.totalPaidAmount + element.totalRestAmount;
          });
        }else{
          this.duesList = [];
        }
        console.log(res);
      },err=>{
        this.filterMenu.closeMenu();
        this.duesList = [];
        this.snack.open(this.translate.instant('ERROR_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });
      })
    }
  }

  private prepareAgreementData(agreementForm: UntypedFormGroup): any {
    let result = agreementForm.getRawValue();
    if(this.form.value.startDate && this.form.value.startDate != ''){
      const startDate = this.datePipe.transform(
        this.form.value.startDate,
        "yyyy-MM-dd"
        );
      const endDate = this.datePipe.transform(
        this.form.value.endDate,
        "yyyy-MM-dd"
        );

        result = {
          ...result,
          startDate:startDate,
          endDate:endDate
        };
    }else{
      result = {
        ...result
      };
    }


    return result;
  }

  exportToExcel(){
    var data = [];
    var val = {
      [this.translate.instant('PAID')]: this.totalPaidAmount,
      [this.translate.instant('NOT_PAID')]: this.totalRestAmount,
      [this.translate.instant('ALL')]: this.totalPaidAmountAndRest
    };
    data.push(val);
    if(this.duesList && this.duesList.length != 0){
      this.exportsFileService.exportToExcel(data,'static-'+new Date().toLocaleDateString('fr-CA'))
    }
  }

  setDateFunction(val):NgbDate{
    const date: NgbDate = new NgbDate(val.year, val.month, val.day);
    return date;
  }

}
