import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class ContactWithTenantsService {

  REST_API: string = APP_CONFIG.API_ENDPOINT;
  API_URL = `${this.REST_API}`;
  
  REST_No_API: string = APP_CONFIG.NO_API_ENDPOINT;
  NO_API_URL = `${this.REST_No_API}`;

  constructor(private http: HttpClient) { }

  searchForMessageText(data): Observable<any> {
    return this.http.get(this.API_URL+'/messageSamples/autoCompleteMessageSampleText'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  sendWhatsAppMessageOrCall(data): Observable<any> {
    return this.http.post(this.API_URL+'/messageSamples/sendWhatsAppMessageOrCall',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  sendAutomaticDuesNotificationsToTenants(data): Observable<any> {
    return this.http.post(this.API_URL+'/dueNotifications/sendAutomaticDuesNotificationsToTenants',data)
      .pipe(
        catchError(this.handleError)
      )
  }
  
  testWhatsAppConnection(): Observable<any> {
    return this.http.get(this.NO_API_URL+'/auth/checkauth')
      .pipe(
        catchError(this.handleError)
      )
  }

  getQRForWhatsappConnection(): Observable<any> {
    return this.http.get(this.NO_API_URL+'/auth/getqr')
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteWhatsappSession(): Observable<any> {
    return this.http.post(this.API_URL+'/messageSamples/disconicttedWhatsApp',{})
      .pipe(
        catchError(this.handleError)
      )
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.message.includes('login')){
      localStorage.removeItem('user');
      localStorage.removeItem('sessionID');
      localStorage.removeItem('token');
      window.location.reload();
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  jsonToQueryString(json) {
    return '?' + 
        Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
  }
}
