import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatGoogleMapsAutocompleteDirective } from './mat-google-maps-autocomplete.directive';



@NgModule({
  declarations: [MatGoogleMapsAutocompleteDirective],
  imports: [
    CommonModule
  ],
  exports:[MatGoogleMapsAutocompleteDirective]
})
export class SheardDirectivesModule { }
