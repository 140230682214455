import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '../menu/menu.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  toppings;
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  public currentLag:string;
  public menuItems;
  public favorites; 
  constructor(public menuService:MenuService,private translate: TranslateService) {
    this.currentLag = this.translate.currentLang;
    if(!this.currentLag){
      this.currentLag = this.translate.getBrowserLang();
    }
    this.translate.onLangChange.subscribe(res=>{
      this.currentLag = res.lang;
    });
   }

  ngOnInit() {
    this.menuItems = this.menuService.getVerticalMenuItems().filter(menu => menu.routerLink != null || menu.href !=null);
  }

}
