import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { AppConfirmComponent } from './app-confirm.component';
// import { LayoutService } from '../layout.service';

interface ConfirmData {
  title?: string;
  message?: string;
  isInSeries?: boolean;
  mode?: 'EDIT' | 'REMOVE';
}

@Injectable()
export class AppConfirmService {
  constructor(
    private dialog: MatDialog,
    // private layoutService: LayoutService
  ) {}

  public confirm(data: ConfirmData = {}): Observable<boolean | string> {
    data.title = data.title || 'Confirm';
    data.message = data.message || 'Are you sure?';
    data.isInSeries = data.isInSeries || false;
    data.mode = data.mode || 'EDIT';
    let dialogRef: MatDialogRef<AppConfirmComponent>;
    dialogRef = this.dialog.open(AppConfirmComponent, {
      width: '380px',
      disableClose: true,
      data: {
        title: data.title,
        message: data.message,
        isInSeries: data.isInSeries,
        mode: data.mode,
      },
      // direction: this.layoutService.layoutConf.dir,
    });
    return dialogRef.afterClosed();
  }
}
