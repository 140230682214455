import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BuildingService } from 'src/app/services/PMAServices/building/building.service';
import { CompoundService } from 'src/app/services/PMAServices/compound/compound.service';
import { OwnersService } from 'src/app/services/PMAServices/owners/owners.service';
import { RealEstateUnitsService } from 'src/app/services/PMAServices/real-estate-units/real-estate-units.service';
import { DateType } from 'ngx-hijri-gregorian-datepicker';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { DuesService } from 'src/app/services/PMAServices/dues/dues.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_DATE_FORMATS } from 'src/app/interfaces/dateFormat';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { RentAgreementsService } from 'src/app/services/PMAServices/rentAgreements/rent-agreements.service';
import { ExportsFileService } from 'src/app/services/PMAServices/exports-file/exports-file.service';
import { Router } from '@angular/router';
import { MatCheckbox } from '@angular/material/checkbox';
import { AppLoaderService } from 'src/app/shared/app-loader/app-loader.service';


@Component({
  selector: 'app-pms-fees-report',
  templateUrl: './pms-fees-report.component.html',
  styleUrls: ['./pms-fees-report.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    DatePipe
  ]
})
export class PmsFeesReportComponent implements OnInit {
  form:UntypedFormGroup;
  ownersList: any;
  compoundList: any;
  BuildingList: any;
  unitList: any;

  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  selectedDateType  =  DateType.Gregorian;  // or DateType.Hijri
  @ViewChild('datePickerFrom') datePickerFrom: any;
  @ViewChild('datePickerTo') datePickerTo: any;
  duesList: any;
  @ViewChild(MatMenuTrigger) filterMenu: MatMenuTrigger;
  
  @ViewChild('checkUnitAll') checkUnitAll: MatCheckbox;

  totalPaidAmount: number = 0;
  totalRestAmount: number = 0;
  totalPaidAmountAndRest: number = 0;
  
  filteredControl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<any[]>;
  options: any[]=[];

  pageType: string = 'pmsFeesReport';
  page: number = 1;
  nodata: boolean = false;

  noFilteredYet: boolean = false;

  constructor(
    public fb: UntypedFormBuilder,
    private ownersService: OwnersService,
    private compoundInfosService: CompoundService, 
    private buildingService: BuildingService,
    private realEstateUnitsService: RealEstateUnitsService,
    private datePipe: DatePipe,
    private duesService: DuesService,
    private snack: MatSnackBar,
    private translate: TranslateService,
    private exportsFileService: ExportsFileService,
    private loading: AppLoaderService,
    private rentAgreementsService: RentAgreementsService,
    private router: Router
  ) { 
    if(this.router.url == '/securityDepositReport'){
      this.pageType = 'securityDepositReport';
    }else if(this.router.url == '/annualRentReport'){
      this.pageType = 'annualRentReport';
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      ownerId:[],
      compoundId:[],
      buildingId:[],
      unitId:[],
      page: this.page,
      limit: 50,
      startDate:['',Validators.compose([Validators.required])],
      endDate: ['',Validators.compose([Validators.required])]
    });

    if(this.pageType == 'securityDepositReport' || this.pageType == 'annualRentReport'){
      this.form.get('startDate').clearValidators();
      this.form.get('startDate').updateValueAndValidity();
      this.form.get('endDate').clearValidators();
      this.form.get('endDate').updateValueAndValidity();
    }

    this.ownersService.getAllOwners().subscribe(res=>{
      if(res){
        this.ownersList = res;
      }
    });

    this.compoundInfosService.getCompoundForSelect().subscribe(res=>{
      if(res){
        this.compoundList = res;
      }
    });

    this.buildingService.getAllBuildings().subscribe(res=>{
      if(res){
        this.BuildingList = res;
      }
    });

    this.realEstateUnitsService.getAllUnits().subscribe(res=>{
      if(res){
        this.unitList = res.data;
      }
    });

    this.filteredOptions = this.filteredControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.fullName),
      map(fullName => fullName ? this._filter(fullName) : this.options.slice())
    );

    this.getData();

    this.startDate = this.setDateFunction({year: new Date().getUTCFullYear(), month:new Date().getUTCMonth(),day: new Date().getUTCDate()});
    this.endDate = this.setDateFunction({year: new Date().getUTCFullYear(), month:new Date().getUTCMonth()+1,day: new Date().getUTCDate()});
    setTimeout(() => {
      this.onDateSelectFrom();
      this.onDateSelectTo();
    }, 500);
    
  }

  getData(){
    if(this.pageType == 'securityDepositReport'){
      this.duesService.getSecurityDeposits(this.form.value).subscribe(res=>{
        if(res && res.length != 0){
          this.duesList = res;
          res.forEach(element => {
            this.totalPaidAmount += element.accountBalance;
          });
        }else{
          this.duesList = [];
        }
      });
    }else if(this.pageType == 'pmsFeesReport'){
      this.duesService.getPmaFeesReport(this.form.value).subscribe(res=>{
        if(res && res.length != 0){
          this.duesList = res;
          res.forEach(element => {
            if(element.totalPaidAmount){
              this.totalPaidAmount += element.totalPaidAmount;
            }else{
              if(element.amount){
                this.totalPaidAmount += element.amount;
              }else{
                this.totalPaidAmount = 0;
              }
            }
          });
        }else{
          if(res.msg == 'error' && res.data == 'data has reached the limit'){
            this.duesList = [];
          }else{
            this.form.patchValue({
              page: this.page++
            });
            this.getData();
          }
          this.duesList = [];
        }
      });
    }
  }

  private _filter(fullName: string): any[] {
    const filterValue = fullName;
    return this.options.filter(option => option.fullName.toLowerCase().includes(filterValue));
  }

  displayFn(name: any): string {
    return name && name.fullName ? name.fullName : '';
  }

  allUnit(event){
    if(event.checked){
      this.page = 1;
      this.form.patchValue({
        ownerId:[],
        compoundId:[],
        buildingId:[],
        unitId: 'ALL',
        page: this.page
      });
    }
  }

  getUnitsList(val){
    var data = {searchPart:''};
    data.searchPart = val.target.value;
    this.realEstateUnitsService.getUnitsList(data).subscribe(res=>{
      if(res){
        res.forEach(element => {
          var name = '';
          if(element.compound){
              if(element.building){
                  name = element.compound.name+"-"+element.building.name+"-"+element.name;
              }else{
                  name = element.compound.name+"-"+element.name;
              }
          }
          if(element.building){
              name = element.building.name+"-"+element.name
          }
        
          if(!element.building && !element.compound){
              name = element.name;
          }
          element.fullName = name;
        });
        this.options = res;
      }
    });
  }
  
  ownerSelected(){
    if(this.checkUnitAll){
      this.checkUnitAll.checked = false;
    }
    this.page = 1;
    this.form.patchValue({      
      compoundId:[],
      buildingId:[],
      unitId:[],
      page: this.page
    });
  }

  compoundSelected(){
    if(this.checkUnitAll){
      this.checkUnitAll.checked = false;
    }
    this.page = 1;
    this.form.patchValue({
      ownerId:[],
      buildingId:[],
      unitId:[],
      page: this.page
    });
  }

  buildingSelected(){
    if(this.checkUnitAll){
      this.checkUnitAll.checked = false;
    }
    this.page = 1;
    this.form.patchValue({
      ownerId:[],
      compoundId:[],
      unitId:[],
      page: this.page
    });
  }

  OnSelectedUnit(event){
    this.form.patchValue({
      ownerId:[],
      compoundId:[],
      buildingId:[],
      unitId: event.value.id
    });
  }

  onDateSelectFrom(){
    let date = this.datePickerFrom?.getSelectedDate(event);
    if(date != 'Invalid date'){
      this.form.patchValue({
        startDate: date
      });
    }
  }

  onDateSelectTo(){
    let date = this.datePickerTo?.getSelectedDate(event);
    if(date != 'Invalid date'){
      this.form.patchValue({
        endDate: date
      });
    }
  }

  closeFilterMenu(){
    this.filterMenu.closeMenu();
  }

  submit(actionType?){
    if(this.form.valid){
      if(actionType != 'AUTO'){
        this.loading.open();
        this.totalPaidAmount = 0;
        this.totalRestAmount = 0;
        this.totalPaidAmountAndRest = 0;
        if(!this.noFilteredYet){
          this.duesList = [];
        }
        this.page = 1;
        this.form.patchValue({
          page: this.page
        });
      }else{
        if(this.duesList.length == 0){
          this.loading.open();
        }
      }

      const dateFilterValue = this.prepareAgreementData(this.form);
      if(!dateFilterValue.unitId || !dateFilterValue.buildingId || !dateFilterValue.compoundId  || !dateFilterValue.ownerId ){
        this.loading.close();
        this.snack.open(this.translate.instant('ERROR_MESSAGE_AT_LEAST_ONE'), this.translate.instant('ERROR'), { duration: 4000 });
        return;
      }

      if(this.pageType == 'securityDepositReport'){
        this.duesService.getSecurityDeposits(dateFilterValue).subscribe(res=>{
          this.loading.close();
          this.filterMenu.closeMenu();
          if(res && res.length != 0){
            this.duesList = res;
            res.forEach(element => {
              this.totalPaidAmount += element.accountBalance;
            });
          }else{
            this.duesList = [];
          }
        },err=>{
          this.loading.close();
          this.filterMenu.closeMenu();
          this.duesList = [];
          this.snack.open(this.translate.instant('ERROR_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });
        });
      }else if(this.pageType == 'pmsFeesReport'){
        this.duesService.getPmaFeesReport(dateFilterValue).subscribe(res=>{
          this.filterMenu.closeMenu();
          this.loading.close();
          if(res && res.length != 0 && !res.msg){
            if(this.duesList?.length == 0){
              this.duesList = res;
              res.forEach(element => {
                if(element.totalPaidAmount){
                  this.totalPaidAmount += element.totalPaidAmount;
                }else{
                  if(element.amount){
                    this.totalPaidAmount += element.amount;
                  }else{
                    if(element['dues.pmaFeesMovements.amount']){
                      this.totalPaidAmount += element['dues.pmaFeesMovements.amount'];
                    }
                  }
                }
              });
            }else{
              res.forEach(element => {
                this.duesList.push(element);
                if(element.totalPaidAmount){
                  this.totalPaidAmount += element.totalPaidAmount;
                }else{
                  if(element.amount){
                    this.totalPaidAmount += element.amount;
                  }else{
                    if(element['dues.pmaFeesMovements.amount']){
                      this.totalPaidAmount += element['dues.pmaFeesMovements.amount'];
                    }
                  }
                }
              });
            }
            if(this.form.value.unitId == 'ALL' || this.form.value.ownerId == 'ALL' || this.form.value.compoundId == 'ALL' || this.form.value.buildingId == 'ALL'){
              this.page+=1;
              this.form.patchValue({
                page: this.page
              });
              this.submit('AUTO');
            }
          }else{
            if(res.msg == 'error' && res.data == 'data has reached the limit'){
              //
              this.nodata = true;
            }else{
              if(this.form.value.unitId == 'ALL' || this.form.value.ownerId == 'ALL' || this.form.value.compoundId == 'ALL' || this.form.value.buildingId == 'ALL'){
                this.page+=1;
                this.form.patchValue({
                  page: this.page
                });
                this.submit('AUTO');
              }
              this.duesList = [];
            }
          }
        },err=>{
          this.loading.close();
          this.filterMenu.closeMenu();
          this.duesList = [];
          this.snack.open(this.translate.instant('ERROR_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });
        });
      }else{
        this.duesService.getAnnualRentReport(dateFilterValue).subscribe(res=>{
          this.noFilteredYet = true;
          this.loading.close();
          this.filterMenu.closeMenu();
          if(res && res.length != 0){
            this.duesList = res;
            res.forEach(element => {
              this.totalPaidAmount += element.amount;
            });
          }else{
            this.duesList = [];
          }
        },err=>{
          this.loading.close();
          this.filterMenu.closeMenu();
          this.duesList = [];
          this.snack.open(this.translate.instant('ERROR_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });
        });
      }
    }
  }

  private prepareAgreementData(agreementForm: UntypedFormGroup): any {
    let result = agreementForm.getRawValue();
    if(this.form.value.startDate && this.form.value.startDate != ''){
      const startDate = this.datePipe.transform(
        this.form.value.startDate,
        "yyyy-MM-dd"
        );
      const endDate = this.datePipe.transform(
        this.form.value.endDate,
        "yyyy-MM-dd"
        );

        result = {
          ...result,
          startDate:startDate,
          endDate:endDate
        };
    }else{
      result = {
        ...result
      };
    }
    return result;
  }

  setDateFunction(val):NgbDate{
    const date: NgbDate = new NgbDate(val.year, val.month, val.day);
    return date;
  }

  exportToExcel(){
    var data = [];
    let val;
    if(this.pageType == 'securityDepositReport'){
      val = [];
      val.push({[this.translate.instant('TOTAL')]: this.totalPaidAmount})
      this.duesList.forEach(element => {
        val.push({
          [this.translate.instant('ACCOUNT_NAME')]: element.accountName,
          [this.translate.instant('AMOUNT')]: Number(element.accountBalance).toFixed(2),
        });
      });
      data = val;
    }else if(this.pageType == 'pmsFeesReport'){
      if(this.form.value.unitId == 'ALL' || this.form.value.ownerId == 'ALL' || this.form.value.compoundId == 'ALL' || this.form.value.buildingId == 'ALL'){
        val = [];
        val.push({[this.translate.instant('TOTAL')]: this.totalPaidAmount})
        this.duesList.forEach(element => {
          val.push({
            [this.translate.instant('NAME')]: element.name,
            [this.translate.instant('AMOUNT')]: element.amount,
          });
        });
        data = val;
      }else {
        val = {
          [this.translate.instant('PAID')]: this.totalPaidAmount,
        };
        data.push(val);
      }
    }else{
      val = [];
      val.push({[this.translate.instant('TOTAL')]: this.totalPaidAmount})
      this.duesList.forEach(element => {
        val.push({
          [this.translate.instant('NAME')]: element.name,
          [this.translate.instant('AMOUNT')]: Number(element.amount).toFixed(2),
        });
      });
      data = val;
    }
    if(this.duesList && this.duesList.length != 0){
      this.exportsFileService.exportToExcel(data,'static-'+new Date().toLocaleDateString('fr-CA'))
    }
  }
}