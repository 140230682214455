import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateType } from 'ngx-hijri-gregorian-datepicker';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MY_DATE_FORMATS } from 'src/app/interfaces/dateFormat';
import { BuildingService } from 'src/app/services/PMAServices/building/building.service';
import { CompoundService } from 'src/app/services/PMAServices/compound/compound.service';
import { OwnersService } from 'src/app/services/PMAServices/owners/owners.service';
import { RealEstateUnitsService } from 'src/app/services/PMAServices/real-estate-units/real-estate-units.service';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    DatePipe
  ]
})
export class FilterMenuComponent implements OnInit {

  form:UntypedFormGroup;
  ownersList: any;
  compoundList: any;
  BuildingList: any;
  unitList: any;

  startDate: NgbDateStruct;
  endDate: NgbDateStruct;

  selectedDateType  =  DateType.Gregorian;  // or DateType.Hijri

  @ViewChild('datePickerFrom') datePickerFrom: any;
  @ViewChild('datePickerTo') datePickerTo: any;
  
  @ViewChild(MatMenuTrigger) filterMenu: MatMenuTrigger;
  
  filteredControl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<any[]>;
  options: any[]=[];

  @Input() filterValues: Object; 
  @Output() submitForm: any = new EventEmitter<any>();
  
  constructor(public fb: UntypedFormBuilder,
    private ownersService: OwnersService,
    private compoundInfosService: CompoundService, 
    private datePipe: DatePipe,
    private buildingService: BuildingService,
    private realEstateUnitsService: RealEstateUnitsService,) { }

  ngOnInit(): void {
    // this.form = this.fb.group({
    //   ownerId:[],
    //   compoundId:[],
    //   buildingId:[],
    //   unitId:[],
    //   startDate:[''],
    //   endDate: ['']
    // });
    this.form = this.fb.group(this.filterValues);
    this.ownersService.getAllOwners().subscribe(res=>{
      if(res){
        this.ownersList = res;
      }
    });

    this.compoundInfosService.getCompoundForSelect().subscribe(res=>{
      if(res){
        this.compoundList = res;
      }
    });

    this.buildingService.getAllBuildings().subscribe(res=>{
      if(res){
        this.BuildingList = res;
      }
    });

    this.realEstateUnitsService.getAllUnits().subscribe(res=>{
      if(res){
        this.unitList = res.data;
      }
    });

    this.filteredOptions = this.filteredControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.fullName),
      map(fullName => fullName ? this._filter(fullName) : this.options.slice())
    );
    
    this.startDate = this.setDateFunction({year: new Date().getUTCFullYear(), month:new Date().getUTCMonth(),day: new Date().getUTCDate()});
    this.endDate = this.setDateFunction({year: new Date().getUTCFullYear(), month:new Date().getUTCMonth()+1,day: new Date().getUTCDate()});
    setTimeout(() => {
      this.onDateSelectFrom();
      this.onDateSelectTo();
    }, 500);
  }

  private _filter(fullName: string): any[] {
    const filterValue = fullName;
    return this.options.filter(option => option.fullName.toLowerCase().includes(filterValue));
  }

  displayFn(name: any): string {
    return name && name.fullName ? name.fullName : '';
  }

  getUnitsList(val){
    var data = {searchPart:''};
    data.searchPart = val.target.value;
    this.realEstateUnitsService.getUnitsList(data).subscribe(res=>{
      if(res){
        res.forEach(element => {
          var name = '';
          if(element.compound){
              if(element.building){
                  name = element.compound.name+"-"+element.building.name+"-"+element.name;
              }else{
                  name = element.compound.name+"-"+element.name;
              }
          }
          if(element.building){
              name = element.building.name+"-"+element.name
          }
        
          if(!element.building && !element.compound){
              name = element.name;
          }
          element.fullName = name;
        });
        this.options = res;
      }
    });
  }
  
  ownerSelected(){
    this.form.patchValue({      
      compoundId:[],
      buildingId:[],
      unitId:[],
    });
  }

  compoundSelected(){
    this.form.patchValue({
      ownerId:[],
      buildingId:[],
      unitId:[],
    });
  }

  buildingSelected(){
    this.form.patchValue({
      ownerId:[],
      compoundId:[],
      unitId:[],
    });
  }

  OnSelectedUnit(event){
    this.form.patchValue({
      ownerId:[],
      compoundId:[],
      buildingId:[],
      unitId: event.value.id
    });
  }

  onDateSelectFrom(){
    let date = this.datePickerFrom?.getSelectedDate(event);
    if(date != 'Invalid date'){
      this.form.patchValue({
        startDate: date
      });
    }
  }

  onDateSelectTo(){
    let date = this.datePickerTo?.getSelectedDate(event);
    if(date != 'Invalid date'){
      this.form.patchValue({
        endDate: date
      });
    }
  }

  closeFilterMenu(){
    this.filterMenu.closeMenu();
  }

  submit(){
    if(this.form.valid){
      const dateFilterValue = this.prepareAgreementData(this.form);
      this.submitForm.emit(dateFilterValue);
      this.closeFilterMenu();
    }
  }

  private prepareAgreementData(agreementForm: UntypedFormGroup): any {
    let result = agreementForm.getRawValue();
    if(this.form.value.startDate && this.form.value.startDate != ''){
      const startDate = this.datePipe.transform(
        this.form.value.startDate,
        "yyyy-MM-dd"
        );
      const endDate = this.datePipe.transform(
        this.form.value.endDate,
        "yyyy-MM-dd"
        );

        result = {
          ...result,
          startDate:startDate,
          endDate:endDate
        };
    }else{
      result = {
        ...result
      };
    }


    return result;
  }

  setDateFunction(val):NgbDate{
    const date: NgbDate = new NgbDate(val.year, val.month, val.day);
    return date;
  }
}
