import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class BuildingService {

  REST_API: string = APP_CONFIG.API_ENDPOINT;
  API_URL = `${this.REST_API}`;
  REST_No_API: string = APP_CONFIG.NO_API_ENDPOINT;
  NO_API_URL = `${this.REST_No_API}`;

  constructor(private http: HttpClient) { }


  getAllBuildings(): Observable<any> {
    //return this.http.get(this.NO_API_URL+'/propertyManagement/showBuildings')
    return this.http.get(this.API_URL+'/buildings/showAllBuildings')
      .pipe(
        catchError(this.handleError)
      )
  }

  getAllBuildingsDataTable(data:any): Observable<any> {
    return this.http.post(this.API_URL+'/buildings/findAllBuildings',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getBuildingDetails(data): Observable<any> {
    return this.http.get(this.API_URL+'/buildings/findBuildingById'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  addBuilding(data: any): Observable<any> {
    return this.http.post(this.API_URL+'/buildings/createBuilding',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateBuilding(data: any): Observable<any> {
    return this.http.post(this.API_URL+'/buildings/updateBuilding',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteBuilding(data: any): Observable<any> {
    return this.http.post(this.API_URL+'/buildings/deleteBuilding',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getBuildingAttachment(data:any): Observable<any> {
    return this.http.get(this.API_URL+'/buildings/findAllBuildingDocs'+this.jsonToQueryString(data))
      .pipe(
        catchError(this.handleError)
      )
  }

  
  deleteBuildingDoc(data): Observable<any> {
    return this.http.post(this.API_URL+'/buildings/deleteBuildingDoc', data)
      .pipe(
        catchError(this.handleError)
      )
  }

  addِBuildingDoc(data): Observable<any> {
    return this.http.post(this.API_URL+'/buildings/createBuildingDoc', data)
      .pipe(
        catchError(this.handleError)
      )
  }
  
  // Error 
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.message.includes('login')){
      localStorage.removeItem('user');
      localStorage.removeItem('sessionID');
      localStorage.removeItem('token');
      window.location.reload();
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  jsonToQueryString(json) {
    return '?' + 
        Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
  }
}
