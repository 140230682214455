import { AfterViewInit, Component, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { Subject } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { DuesService } from 'src/app/services/PMAServices/dues/dues.service';
import { OwnersService } from 'src/app/services/PMAServices/owners/owners.service';
import { PMASettingsService } from 'src/app/services/PMAServices/PMA-settings/pma-settings.service';
import { ActionMenuComponent } from 'src/app/shared/action-menu/action-menu.component';
import { AppConfirmService } from 'src/app/shared/app-confirm/app-confirm.service';
import { MenuComponent } from './menu/menu.component';

@Component({
  selector: 'app-tenants-dues',
  templateUrl: './tenants-dues.component.html',
  styleUrls: ['./tenants-dues.component.scss']
})
export class TenantsDuesComponent implements OnInit, AfterViewInit {

  @Input() agreementId: number;
  duesList:any;
  dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  dtOptions: any = {} 
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  currentUser: any = this.loginService.getUser();

  addTenantDuesPage: boolean = true;
  updateTenantDuesPage: boolean = true;
  deleteTenantDuesPage: boolean = true;

  @ViewChild('demoNg') demoNg: TemplateRef<MenuComponent>;
  @ViewChild('dataTable') dataTable:DataTablesModule;

  data: any;

  pmaSettingsInfo: any;
  public pmaIsThereTax: boolean = false;
  pmaTaxPercentage: number = 0;
  
  constructor(private duesService: DuesService,
    private router: Router,
    private snack: MatSnackBar,
    private confirmService: AppConfirmService,
    private translate: TranslateService,
    private loginService: LoginService,
    private pmaSettingsService: PMASettingsService,
    public dialog: MatDialog,
    private ownersService: OwnersService) { 
      this.getPMASettings();
    }
    
  ngOnInit(): void {
    this.currentUser.subscribe(res=>{
      if(res.isAdmin == 0 || res.isAdmin == false){
        if(res.permission && res.permission.tenantsDues_page.includes('ADD')){
          this.addTenantDuesPage = true;
        }else{
          this.addTenantDuesPage = false;
        }
        if(res.permission && res.permission.tenantsDues_page.includes('EDIT')){
          this.updateTenantDuesPage = true;
        }else{
          this.updateTenantDuesPage = false;
        }
        if(res.permission && res.permission.tenantsDues_page.includes('DELETE')){
          this.deleteTenantDuesPage = true;
        }else{
          this.deleteTenantDuesPage = false;
        }
      }
    });
    if(this.agreementId){
      this.getDuesForSpecificAgreement();
    }else{
      this.fatchData();
    }
    
  }

  fatchData(){
    setTimeout(() => {
        const self = this;
        this.dtOptions = {
          pagingType: 'full_numbers',
          lengthMenu: [[ 10, 25, 50,100,500,1000, -1],[10,25,50,100,500,1000,"Show All"]],
          pageLength: 10,
          serverSide: true,
          processing: true,
          searching: true,
          ajax: (dataTablesParameters: any, callback) => {
            this.duesService.getAllDuesList(dataTablesParameters).subscribe(resp => {
              this.duesList = resp.data;
              if(this.duesList && this.duesList.length != 0){
                resp.data.forEach(element => {
                  if(element.payStatus == 'NON_PAID'){
                    element.pmaNewIsThereTax = this.pmaIsThereTax;
                    element.pmaNewTaxPercentage = this.pmaTaxPercentage;
                  }
                  if(element.payStatus == 'NON_PAID'){
                    if(element.dueType == 'COMMISSION'){
                        element.pmaIsThereTax = this.pmaIsThereTax;  
                        element.pmaTaxPercentage = this.pmaTaxPercentage;
                        element.pmaTaxAmount = +(((this.pmaTaxPercentage * element.dueAmountWithoutTax) /100).toFixed(2));
                        element.dueAmountWithTax = element.dueAmountWithoutTax+element.pmaTaxAmount;
                        element.restAmount = element.dueAmountWithoutTax+element.pmaTaxAmount;
            
                    // } else if(element.dueType == 'SECURITY_DEPOSIT' || element.dueType == 'SERVICE_FEES'){
                      
                    }else if(element.dueType == 'SECURITY_DEPOSIT'){
                      //
                    } else {
                        element.unitIsThereTax = element.rentAgreement?.unit?.isThereTax;
                        if(element.unitIsThereTax == true){
                          element.unitTaxPercentage = element.rentAgreement?.unit?.ownerTaxNumber?.taxPercentage;
                          if(!element.unitTaxPercentage) element.unitTaxPercentage = 0;
                          element.unitTaxAmount = +(((element.unitTaxPercentage * element.dueAmountWithoutTax) /100).toFixed(2));
                        } else {
                          element.unitTaxPercentage = 0;
                          element.unitTaxAmount = 0;
                        }
                        element.dueAmountWithTax = element.dueAmountWithoutTax+element.unitTaxAmount;
                        element.restAmount = element.dueAmountWithoutTax+element.unitTaxAmount;
                    }
                  }else{
                    if(element.unitIsThereTax == true){
                      element.unitTaxPercentage = element.rentAgreement?.unit?.ownerTaxNumber?.taxPercentage;
                      if(!element.unitTaxPercentage) element.unitTaxPercentage = 0;
                      element.unitTaxAmount = +(((element.unitTaxPercentage * element.dueAmountWithoutTax) /100).toFixed(2));
                    } else {
                      element.unitTaxPercentage = 0;
                      element.unitTaxAmount = 0;
                    }
                    element.dueAmountWithTax = element.dueAmountWithoutTax+element.unitTaxAmount;
                  }
                });
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                  // dtInstance.on('draw.dt', function () {
                    // if ($('.dataTables_empty').length > 0) {
                    //   $('.dataTables_empty').remove();
                    // }
                  // });
                });
              }
              callback({
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                data: resp.data
              });
            });
          },
          columns:[
            {data:"id"},
            {data:"dueDate",
                render: function(data, type, row, meta) {
                    var date = '';
                    if(row.dueDate && row.dueDate != 0){
                        date = new Date(row.dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit'});
                    }
                    return date;
                }
            },
            {data:"rentalInstallmentStartAt",
                render: function(data, type, row, meta) {
                    var date = '';
                    if(row.rentalInstallmentStartAt && row.rentalInstallmentStartAt != 0){
                        date = new Date(row.rentalInstallmentStartAt).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit'});
                    }
                    return date;
                }
            },
            {data: "createdAt",
                render: function(data, type, row, meta) {
                    return new Date(row.createdAt).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',hour12: true });
                }
            },
            {data: "createdByPMAccountUser?.firstName",
                render: function(data, type, row, meta) {
                    return `${row.createdByPMAccountUser?.firstName ? row.createdByPMAccountUser?.firstName: ''} 
                            ${row.createdByPMAccountUser?.fatherName ? row.createdByPMAccountUser?.fatherName: ''} 
                            ${row.createdByPMAccountUser?.grandFatherName ? row.createdByPMAccountUser?.grandFatherName: ''} 
                            ${row.createdByPMAccountUser?.lastName ? row.createdByPMAccountUser?.lastName: ''}`;
                }
            },
            {data:"updatedAt",
                render: function(data, type, row, meta) {
                    return new Date(row.updatedAt).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',hour12: true });
                }
            },
            {data: "lastModifiedByPMAUser?.firstName",
                render: function(data, type, row, meta) {
                    return `${row.lastModifiedByPMAUser?.firstName ? row.lastModifiedByPMAUser?.firstName: ''} 
                            ${row.lastModifiedByPMAUser?.fatherName ? row.lastModifiedByPMAUser?.fatherName: ''} 
                            ${row.lastModifiedByPMAUser?.grandFatherName ? row.lastModifiedByPMAUser?.grandFatherName: ''} 
                            ${row.lastModifiedByPMAUser?.lastName ? row.lastModifiedByPMAUser?.lastName: ''}`;
                }
            },
            {data:"rentAgreementId"},
            {data: "tenantName",
                render: function(data, type, row, meta) {
                    var tenant_name='';
                    if(row.rentAgreement && row.rentAgreement.tenant.tenantTypeId == '2'){
                        tenant_name = row.rentAgreement.tenant.entityName;
                    }else{
                        tenant_name = `${row.rentAgreement?.tenant?.firstName} ${row.rentAgreement?.tenant?.fatherName} ${row.rentAgreement?.tenant?.grandfatherName} ${row.rentAgreement?.tenant?.lastName}`;
                    }
                    return tenant_name;
                }
            },
            {data:"mobileNumber",
                render: function(data, type, row, meta){
                    var mobileNumber='';
                    if(row.rentAgreement && row.rentAgreement.tenant?.mobileNumber){
                        mobileNumber = row.rentAgreement.tenant.mobileNumber;
                    }
                    return mobileNumber;
                }
            },
            {data:"compoundName",
                render: function(data, type, row, meta){
                    var compoundName='';
                    if(row.rentAgreement && row.rentAgreement?.unit?.compound){
                        compoundName = row.rentAgreement?.unit?.compound.name;
                    }
                    return compoundName;
                }
            },
            {data:"buildingName",
                render: function(data, type, row, meta){
                    var buildingName='';
                    if(row.rentAgreement && row.rentAgreement?.unit?.building){
                        buildingName = row.rentAgreement?.unit?.building.name;
                    }
                    return buildingName;
                }
            },
            {data:"unitName",
                render: function(data, type, row, meta){
                    var unitName= '';
                    if(row.rentAgreement && row.rentAgreement?.unit?.name){
                        unitName = row.rentAgreement?.unit?.name;
                    }
                    return unitName;
                }
            },
            {data: "ownerName",
                render: function(data, type, row, meta) {
                    var owners = '';
                    if(row?.rentAgreement?.unit.ownershipType == 'WHOLLY_OWNED'){
                        row?.rentAgreement?.unit.realEstateOwners.forEach(function(element,index){
                            if(element.owner.ownerTypeId == '1'){
                                owners += `<p>المالك ${index+1}: ${element.owner.firstName} ${element.owner.fatherName} ${element.owner.grandfatherName} ${element.owner.lastName} </p>`;
                            }else{
                                owners += `<p>المالك ${index+1}: `+element.owner.entityName+'</p>';   
                            }
                        });
                    }
                    if(row?.rentAgreement?.unit.ownershipType == 'CONNECTED_TO_BUILDING'){
                        row?.rentAgreement?.unit.building.realEstateOwners.forEach(function(element,index){
                            if(element.owner.ownerTypeId == '1'){
                                owners += `<p>المالك ${index+1}: ${element.owner.firstName} ${element.owner.fatherName} ${element.owner.grandfatherName} ${element.owner.lastName} </p>`
                            }else{
                                owners += `<p>المالك ${index+1}: `+element.owner.entityName+'</p>';   
                            }
                        });
                    }
                    if(row?.rentAgreement?.unit.ownershipType == 'CONNECTED_TO_COMPOUND'){
                        row?.rentAgreement?.unit.compound.realEstateOwners.forEach(function(element,index){
                            if(element.owner.ownerTypeId == '1'){
                                owners += `<p>المالك ${index+1}: ${element.owner.firstName} ${element.owner.fatherName} ${element.owner.grandfatherName} ${element.owner.lastName} </p>`;
                            }else{
                                owners += `<p>المالك ${index+1}: `+element.owner.entityName+'</p>';   
                            }
                        });
                    }
                    return owners;
                }
            },
            {data:"dueType",
                render: function(data, type, row, meta){
                    var dueType = '';
                    if(row.dueType == "COMMISSION"){
                        dueType = 'عمولة';
                    }
                    if(row.dueType == "SECURITY_DEPOSIT"){
                        dueType = 'تأمين';
                    }
                    if(row.dueType == "RENT_INSTALLMENT"){
                        dueType = 'قسط إيجاري';
                    }
                    if(row.dueType == "MAINTENANCE"){
                        dueType = 'صيانة';
                    }
                    if(row.dueType == "DAMAGE_FINE"){
                        dueType = 'غرامة تلفيات';
                    }
                    if(row.dueType == "DELAY_PENALTY"){
                        dueType = 'غرامة تأخير';
                    } 
                    if(row.dueType == "SERVICE_FEES"){
                        dueType = 'رسوم الخدمة';
                    } 
                      
                    return dueType;  
                }
            },
            {data:"note"},
            {data:"rentalInstallmentStartAt",
                render: function(data, type, row, meta) {
                    var date = '';
                    if(row.rentalInstallmentStartAt){
                        date = new Date(row.rentalInstallmentStartAt).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit'});
                    }
                    return date;
                }
            },
            {data:"rentalInstallmentEndAt",
                render: function(data, type, row, meta) {
                    var date = '';
                    if(row.rentalInstallmentEndAt){
                        date = new Date(row.rentalInstallmentEndAt).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit'});
                    }
                    return date;
                }
            },
            {data:"unitIsThereTax",
            
                render: function(data, type, row, meta) {
                    var is_tax ='';
                    var unitIsThereTax = false;
                    if(row.payStatus == 'NON_PAID'){
                      if(row.dueType == 'COMMISSION'){
                        if(row.pmaIsThereTax == true && row.pmaTaxPercentage > 0)
                        unitIsThereTax = true;
                        else unitIsThereTax = false;
                      }
                      else if(row.dueType == 'SECURITY_DEPOSIT'){
                        unitIsThereTax = false;
                      }
                      else {
                        if(row.rentAgreement?.unit?.isThereTax == true && row.rentAgreement?.unit?.ownerTaxNumber?.taxPercentage > 0){
                          unitIsThereTax = true
                        } else unitIsThereTax = false;
                      }
                    }
                    if(row.payStatus == 'FULL_PAID'){
                      unitIsThereTax = row.unitIsThereTax;
                    }
                    if(unitIsThereTax == true){
                        is_tax = 'نعم';
                    }else if(row.pmaIsThereTax == 1 || row.pmaIsThereTax == true && row.dueType == "COMMISSION"){
                        is_tax = 'نعم';
                    }else{
                        is_tax = 'لا';
                    }
                      
                    return is_tax;
                }
            },
            {data:"dueDate",
                render: function(data, type, row, meta) {
                    var taxNumber='';
                    if(row.rentAgreement?.unit && row.rentAgreement?.unit?.ownerTaxNumber){
                        taxNumber= row.rentAgreement?.unit?.ownerTaxNumber?.taxNumber;
                    }
                    return taxNumber;
                }
            },
            {data:"currency",
                render: function(data, type, row, meta) {
                    var currency ='';
                    if(row.currency == 'SAR'){
                        currency = 'ريال'
                    }
                    return currency;
                }
            },
            {data:"dueAmountWithoutTax"},
            {data:"unitTaxPercentage",
                render: function(data, type, row, meta) {
                    var taxPercentage = 0;
                    if(row.payStatus == 'NON_PAID'){
                      if(row.dueType == "COMMISSION"){
                        if(row.pmaIsThereTax == true){
                          taxPercentage = row.pmaNewTaxPercentage;
                        } 
                      }else if(row.dueType == 'SECURITY_DEPOSIT'){
                        //
                      } else {
                        if(row.unitIsThereTax == true){
                          taxPercentage = row.rentAgreement?.unit?.ownerTaxNumber?.taxPercentage;
                        }
                      }
                    } else {
                      if(row.dueType == "COMMISSION")
                        taxPercentage = row.pmaTaxPercentage;
                      else 
                        taxPercentage = row.unitTaxPercentage;
                    }
                    return taxPercentage;
                }
            },
            {data:"unitTaxAmount",
                render: function(data, type, row, meta) {
                    var taxPercentage=0
                    var unitIsThereTax;

                    if(row.payStatus == 'NON_PAID'){
                      if(row.dueType == "COMMISSION"){
                        unitIsThereTax = row.pmaIsThereTax;
                        if(row.pmaIsThereTax == true){
                          taxPercentage = row.pmaNewTaxPercentage;
                        } 
                      }else if(row.dueType == 'SECURITY_DEPOSIT'){
                        unitIsThereTax = false;
                      } else {
                        unitIsThereTax = row.unitIsThereTax;
                        if(row.unitIsThereTax == true){
                          taxPercentage = row.rentAgreement?.unit?.ownerTaxNumber?.taxPercentage;
                        }
                      }
                    } else {
                      if(row.dueType == "COMMISSION"){
                        taxPercentage = row.pmaTaxPercentage;
                        unitIsThereTax = row.pmaIsThereTax;
                      }else if(row.dueType == 'SECURITY_DEPOSIT'){
                        unitIsThereTax = false;
                      }
                      else {
                        unitIsThereTax = row.unitIsThereTax;
                        taxPercentage = row.unitTaxPercentage;
                      }
                    }

                    var tax = (1 + taxPercentage / 100);
                    var tax_amount = 0;
                    if(unitIsThereTax){
                        tax_amount = +(((row.dueAmountWithoutTax * taxPercentage) / 100).toFixed(2));
                    }
                    return tax_amount;
                }
            },
            {data:"dueAmountWithTax",
                render: function(data, type, row, meta) {
                    var taxPercentage = 0;
                    var unitIsThereTax = row.rentAgreement?.unit?.isThereTax;;
                    var dueAmountWithTax = row.dueAmountWithTax;
                    if(row.dueType == "COMMISSION"){
                      if(row.pmaIsThereTax == true){
                        taxPercentage = row.pmaNewTaxPercentage;
                      }
                      var tax = (1 + taxPercentage / 100);
                      var tax_amount = '';
                      if(unitIsThereTax){
                          tax_amount = ((row.dueAmountWithoutTax * taxPercentage) / 100).toFixed(2);
                          dueAmountWithTax = (parseFloat(row.dueAmountWithoutTax) + parseFloat(tax_amount)).toFixed(2)
                      }
                        
                    }else if(row.dueType == 'SECURITY_DEPOSIT'){
                      unitIsThereTax = false;
                    } else {
                      if(row.unitIsThereTax == true){
                        taxPercentage = row.rentAgreement?.unit?.ownerTaxNumber?.taxPercentage;
                      }
                      var tax = (1 + taxPercentage / 100);
                      var tax_amount = '';
                      if(unitIsThereTax){
                          tax_amount = ((row.dueAmountWithoutTax * taxPercentage) / 100).toFixed(2);
                          dueAmountWithTax = (parseFloat(row.dueAmountWithoutTax) + parseFloat(tax_amount)).toFixed(2)
                      }
                    }
                    return dueAmountWithTax;
                }
            },
            {data:"paidAmount"},
            {data:"restAmount",
              render: function(data, type, row, meta){
                var restAmount = row.restAmount;
                var taxPercentage = 0;
                if(row.payStatus == 'NON_PAID'){
                  if(row.dueType == "COMMISSION"){
                    if(row.pmaIsThereTax == true)
                    taxPercentage = row.pmaNewTaxPercentage;
                  }else if(row.dueType == 'SECURITY_DEPOSIT'){
                    //
                  } else {
                    if(row.unitIsThereTax == true){
                      taxPercentage = row.rentAgreement?.unit?.ownerTaxNumber?.taxPercentage;
                    }
                  }
                  var tax_amount = ((row.dueAmountWithoutTax * taxPercentage) / 100).toFixed(2);
                  restAmount = (parseFloat(row.dueAmountWithoutTax) + parseFloat(tax_amount)).toFixed(2);
                }
                return restAmount;
              }
            },
            {data:"dueStatus",
                render: function(data, type, row, meta){
                    var dueStatus = '';
                    if(row.dueStatus == 'EARLY_DUE'){
                      dueStatus = 'لم يحن موعد السداد';
                    }
                    if(row.dueStatus == 'DUE'){
                      dueStatus = 'وقت السداد';
                    }
                    if(row.dueStatus == 'LATE_DUE'){
                      dueStatus = 'متأخر في السداد';
                    }
                    if(row.dueStatus == 'COMPLETED_DUE'){
                      dueStatus = 'تم السداد';
                    }
                    
                    // if(row.status == 'COMPELETED'){
                    //     status = 'مكتمل السداد';
                    // }
                    // if(row.status == 'CANCELED'){
                    //     status = 'ملفي';
                    // }
                    // if(row.status == 'CLOSED'){
                    //   dueStatus = 'مغلق';
                    // }
                    return dueStatus;
                }
            },
            {data:"payStatus",
                render: function(data, type, row, meta){
                    var payStatus = '';
                    if(row.payStatus == 'NON_PAID'){
                      payStatus = 'غير مدفوع';
                    }
                    if(row.payStatus == 'PARTIAL_PAID'){
                      payStatus = 'مدفوع جزئي';
                    }
                    if(row.payStatus == 'FULL_PAID'){
                      payStatus = 'مدفوع كلي';
                    }
                    return payStatus;
                }
            },
            // {
            //   data:null,
            //   defaultContent: '',
            //   orderable: false,
            //   searchable: false,
            //   ngTemplateRef: {
            //     ref: this.demoNg
            //   }
            // },
          ],
          dom: 'Blfrtip',
          select: true,
          buttons: [
            {
              extend: 'colvis',
              text: '<i class="fa-solid fa-eye"></i>',
              titleAttr: 'Colvis'
            },
            {
              extend: 'copyHtml5',
              text: '<i class="fa-solid fa-copy"></i>',
              titleAttr: 'Copy'
            },
            {
              extend: 'print',
              text: '<i class="fa-solid fa-print"></i>',
              titleAttr: 'Print'
            },
            {
              extend: 'excelHtml5',
              text: '<i class="fa-solid fa-file-excel"></i>',
              titleAttr: 'Excel'
            },
            {
              extend: 'csvHtml5',
              text: '<i class="fa-solid fa-file-csv"></i>',
              titleAttr: 'CSV'
            },
            {
              extend:    'pdfHtml5',
              text:      '<i class="fa-solid fa-file-pdf"></i>',
              titleAttr: 'PDF'
            },
            {
              text: '<i class="fa-solid fa-pen-to-square"></i>',
              className: ['edit', 'disabled'],
              disabled: true,
              key: 'edit',
              action: function (e, dt, node, config) {
                self.data = dt.rows({ selected: true }).data()[0]
                if(self.data){
                  self.editBuilding();
                }else{
                  self.snack.open(self.translate.instant('PLEASE_SELECT_VALUE_FIRST'), self.translate.instant('ERROR'), { duration: 4000 });
                }
              }
            },
            {
              text: '<i class="fa-solid fa-trash"></i>',
              className: ['delete', 'disabled'],
              key: 'delete',
              action: function (e, dt, node, config) {
                self.data = dt.rows({ selected: true }).data()[0]
                if(self.data){
                  self.deleteDue();
                }else{
                  self.snack.open(self.translate.instant('PLEASE_SELECT_VALUE_FIRST'), self.translate.instant('ERROR'), { duration: 4000 });
                }
              }
            }
          ],
          columnDefs: [
            { orderable: false, targets: [4,5,6,8,9,10,11,12,13] },
          ],
          rowCallback: (row: any, data: any[] | Object, index: number) => {
            const self = this;
            $('td', row).off('click');
            $('td', row).on('click', () => {
                this.data = data;
                this.dtElement.dtInstance.then((dtInstance: any) => {
                  if (row.className.includes('selected')){
                    dtInstance.buttons('.edit').disable();
                    dtInstance.buttons('.delete').disable();
                  }else{
                    dtInstance.buttons('.edit').enable();
                    dtInstance.buttons('.delete').enable();
                  }
                })
            });
            return row;
          }
        };
    });
  }

  public openActionDialog(){
    let dialogRef = this.dialog.open(ActionMenuComponent);
    dialogRef.afterClosed().subscribe(actionType => {
        console.log(actionType)
    });
  }
  onCaptureEvent(event: any): void {
    //Stuff
    console.log(event);
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    if(this.dtElement){
        setTimeout(() => {
            this.dtTrigger.next(this.dtOptions);
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.columns([0, 1, 7, 8,12,13,14,10,11,15,27,28]).every(function() {
                const that = this;
                $('input, select, #duesStatus, #duesType', this.footer()).on('keyup change', function() {
                    if (that.search() !== this['value']) {
                        that
                            .search(this['value'])
                            .draw();
                    }
                });
            });
            // race condition fails unit tests if dtOptions isn't sent with dtTrigger
              // dtInstance.columns().every(function () {
              //   const that = this;
              //   $('input', this.footer()).on('keyup change', function () {
              //     if (that.search() !== this['value']) {
              //       that
              //         .search(this['value'])
              //         .draw();
              //     }
              //   });
              // });
            });
          }, 200);
        //   this.dtTrigger.next();
    }

  }

  getDuesForSpecificAgreement(){
    var data = {agreementId:this.agreementId};
    this.duesService.getCollectionsDuesList(data).subscribe(res=>{
      if(res && res.length != 0){
        console.log(res);
        
        this.duesList = this.updateShowDueTaxes(res);
        // this.duesList = res;
      }else{
        this.duesList = [];
      }
      document.getElementById('main').scrollTop = 0;
    },err=>{
      this.duesList = [];
    })
  }

  editBuilding(){
    this.router.navigate(['/tenantsDues/addTenantDue'],this.data);
  }

  deleteDue(){
    var data = {id: this.data.id};
    this.confirmService.confirm({ message: this.translate.instant('CONFIRM') }).subscribe(res=>{
      if(res){
        this.duesService.deleteDue(data).subscribe(res=>{
          if(res && res.msg == 'done'){
            this.snack.open(this.translate.instant('SUCCESS_DELETE_MESSAGE'), this.translate.instant('DONE'), { duration: 4000 });
            this.duesList.forEach((element,index) => {
              if(element.id == this.data.id){
                this.duesList.splice(index,1);
              }
            });
          }
          if(res.msg == 'error'){
            this.snack.open(res.data, this.translate.instant('ERROR'), { duration: 6000 });
          }
        },err=>{
          this.snack.open(err.message, this.translate.instant('ERROR'), { duration: 6000 });
        });
      }
    })
  }

  getPMASettings(){
    this.pmaSettingsService.getPMASettings().subscribe(res=>{
      this.pmaSettingsInfo = res;
      this.pmaIsThereTax = this.pmaSettingsInfo.isThereTax;
      this.pmaTaxPercentage = this.pmaSettingsInfo.settings.taxPercentage;
    })
  }

  updateShowDueTaxes(rows: any){
    rows.forEach(element => {
      if(element.payStatus == 'NON_PAID'){
        if(element.dueType == 'COMMISSION'){
            element.pmaIsThereTax = this.pmaIsThereTax;  
            element.pmaTaxPercentage = this.pmaTaxPercentage;
            element.pmaTaxAmount = +(((this.pmaTaxPercentage * element.dueAmountWithoutTax) /100).toFixed(2));
            element.dueAmountWithTax = element.dueAmountWithoutTax+element.pmaTaxAmount;
            element.restAmount = element.dueAmountWithoutTax+element.pmaTaxAmount;

        // } else if(element.dueType == 'SECURITY_DEPOSIT' || element.dueType == 'SERVICE_FEES'){
          
        } else {
            element.unitIsThereTax = element.rentAgreement?.unit?.isThereTax;
            if(element.unitIsThereTax == true){
              element.unitTaxPercentage = element.rentAgreement?.unit?.ownerTaxNumber?.taxPercentage;
              if(!element.unitTaxPercentage) element.unitTaxPercentage = 0;
              element.unitTaxAmount = +(((element.unitTaxPercentage * element.dueAmountWithoutTax) /100).toFixed(2));
            } else {
              element.unitTaxPercentage = 0;
              element.unitTaxAmount = 0;
            }
            element.dueAmountWithTax = element.dueAmountWithoutTax+element.unitTaxAmount;
            element.restAmount = element.dueAmountWithoutTax+element.unitTaxAmount;
        }
      }
      else{
        if(element.unitIsThereTax == true){
          element.unitTaxPercentage = element.rentAgreement?.unit?.ownerTaxNumber?.taxPercentage;
          if(!element.unitTaxPercentage) element.unitTaxPercentage = 0;
          element.unitTaxAmount = +(((element.unitTaxPercentage * element.dueAmountWithoutTax) /100).toFixed(2));
        } else {
          element.unitTaxPercentage = 0;
          element.unitTaxAmount = 0;
        }
        element.dueAmountWithTax = element.dueAmountWithoutTax+element.unitTaxAmount;
        element.restAmount = (element.dueAmountWithoutTax+element.unitTaxAmount-element.paidAmount).toFixed(2);
      }
    });
    return rows;
  }

  getIsThereTax(item){
    var isThereTax = false;
    if(item.dueType == 'COMMISSION'){
      if(this.pmaIsThereTax == true && this.pmaTaxPercentage > 0)
        isThereTax = true;
      else isThereTax = false;
    }
    else if(item.dueType == 'SECURITY_DEPOSIT'){
      
    }
    else {
      if(item.rentAgreement?.unit?.isThereTax == true && item.rentAgreement?.unit?.ownerTaxNumber?.taxPercentage > 0){
        isThereTax = true
      } else isThereTax = false;
    }
    return isThereTax;
  }

  getTax(item){
    var taxAmount = 0;
    if(item.dueType == 'COMMISSION'){
      if(item.isTaxPaid == 1)
      taxAmount = item.pmaTaxAmount;
      else{
        if(this.pmaIsThereTax == true && this.pmaTaxPercentage > 0)
          taxAmount = +((this.pmaTaxPercentage*item.dueAmountWithoutTax) / 100).toFixed(2);
        else taxAmount = 0;
      }
    }
    else if(item.dueType == 'SECURITY_DEPOSIT'){
      
    }
    else {
      if(item.isTaxPaid == 1)
        taxAmount = item.unitTaxAmount;
      else {
        if(item.rentAgreement?.unit?.isThereTax == true && item.rentAgreement?.unit?.ownerTaxNumber?.taxPercentage > 0){
          taxAmount = +((item.rentAgreement?.unit?.ownerTaxNumber?.taxPercentage*item.dueAmountWithoutTax) /100).toFixed(2);
        } else taxAmount = 0;
      }
    }
    return taxAmount;
  }

  
}
