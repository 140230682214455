import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class RentAgreementsService {

  REST_API: string = APP_CONFIG.API_ENDPOINT;
  API_URL = `${this.REST_API}`;
  REST_No_API: string = APP_CONFIG.NO_API_ENDPOINT;
  NO_API_URL = `${this.REST_No_API}`;

  constructor(private http: HttpClient) { }

  getAllRentAgreementsList(data): Observable<any> {
    return this.http.post(this.API_URL+'/rentAgreements/findAllRentAgreementsByStatus',data)
    .pipe(
      catchError(this.handleError)
    )
  }

  getUnrentedUnits(data:any): Observable<any>{
    return this.http.get(this.API_URL+'/units/findAllUnrentedUnits'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

  getTenantsAutoCompleteList(data:any): Observable<any>{
    return this.http.get(this.NO_API_URL+'/propertyManagement/getTenantsNameAutoComplete'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

  getRentAgreementWithFiltered(data:any): Observable<any>{
    return this.http.get(this.API_URL+'/rentAgreements/findAllRentAgreementsByParameterId'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

  getRentAgreementWithFilteredDate(data:any): Observable<any>{
    return this.http.get(this.API_URL+'/rentAgreements/findAllRentAgreementsBeforeSpecificEndDate'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }
  
  getTaxNumber(data:any): Observable<any>{
    return this.http.get(this.API_URL+'/owners/findPercentageOFOwnerTaxNumber'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

  getRentAgreemenListBySearch(data:any): Observable<any>{
    // return this.http.get(this.NO_API_URL+'/propertyManagement/getTenantsOrUnitsOrAgreementIdForAutoComplete'+this.jsonToQueryString(data))
    return this.http.get(this.API_URL+'/rentAgreements/findActiveRentAgreementsByTenantNameOrUnitNameOrRentAgreementId'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

  getRentAgreemenListButNotOutBySearch(data:any): Observable<any>{
    return this.http.get(this.API_URL+'/rentAgreements/findTenantsOrUnitsOrAgreementIdNotOutForAutoComplete'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

  

  getRentAgreementByIdForRenew(data:any): Observable<any>{
    return this.http.get(this.API_URL+'/rentAgreements/findOneWithAllInfo'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

  addRentAgreement(data): Observable<any> {
    return this.http.post(this.API_URL+'/rentAgreements/createRentAgreement',data)
    .pipe(
      catchError(this.handleError)
    )
  }

  
  changeVerifiedByEjarPlatformStatus(data): Observable<any> {
    return this.http.post(this.API_URL+'/rentAgreements/changeVerifiedByEjarPlatformStatus',data)
    .pipe(
      catchError(this.handleError)
    )
  }

  updateRentAgreement(data): Observable<any> {
    return this.http.post(this.API_URL+'/rentAgreements/renewRentAgreement',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  changeRentAgreementStatus(data): Observable<any> {
    return this.http.post(this.API_URL+'/rentAgreements/deleteRentAgreement',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteRentAgreementDues(data): Observable<any> {
    return this.http.delete(this.API_URL+'/dues/deleteDuesByRentAgreementId',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  addEjarPlatformContractNo(data): Observable<any> {
    return this.http.post(this.API_URL+'/rentAgreements/updateContractNumberInEjarPlatform',data)
      .pipe(
        catchError(this.handleError)
      )
  }

  getRentAgreementByTenantId(data:any): Observable<any>{
    return this.http.get(this.API_URL+'/rentAgreements/findAllRentAgreementsByTenantId'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }
  // Error 
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.message.includes('login')){
      localStorage.removeItem('user');
      localStorage.removeItem('sessionID');
      localStorage.removeItem('token');
      window.location.reload();
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  jsonToQueryString(json) {
    return '?' + 
        Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
  }

  getUnitTaxNumberInfoByUnitId(data:any): Observable<any>{
    return this.http.get(this.API_URL+'/rentAgreements/findUnitTaxNumberInfoByUnitId'+this.jsonToQueryString(data))
    .pipe(
      catchError(this.handleError)
    )
  }

}
